import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {

    private _authToken: string;

  constructor(private _router:Router) {
      this._authToken = "";
  }


    get authToken(): string {
        this._authToken = sessionStorage.getItem("token");
        if(this._authToken === "" || this._authToken === null) {
            this._router.navigate(["/"]);
        }
        return this._authToken;
    }


    set authToken(value: string) {
        this._authToken = value;
        sessionStorage.setItem("token", this._authToken);
    }
}
