
export const GROUP_TYPE = 3;

export interface Group {
    name?: string;
    id?: number;
    responsibleFullName?: string;
    responsibleEmailAddress?: string;
    companyId?: number;
    companyName?: string;
    userRef?: string;
    actions?: boolean;
    type?: number;
}

export const EMPTY_GROUP_PLACE_HOLDER: Group = {
    id: null,
    name: "Nessun gruppo presente",
    actions: false
};
export const EMPTY_LIST_GROUP_PLACE_HOLDER: Group = {
    id: null,
    name: "Nessun gruppo trovato corrispondente ai criteri di ricerca inseriti",
    actions: false
};