import {User} from './user.component';
import {Location} from './location.component';

export const COMPANY_TYPE = 7;


export interface Company {
    name: string;
    iva: number;
    moderator: User;
    activity?: string;
    headquarters: Location;
    branches?: Location[];
    actions?: boolean;
    employees?: User[];
    id?: number;
}


export const EMPTY_COMPANIES_PLACE_HOLDER: Company = {
    id: null,
    name: 'Nessuna società presente',
    iva: null,
    moderator: {
        name: '',
        surname: '',
        qualification: {id: null, title: '', actions: false}
    },
    headquarters: {
        region: {
            id: null,
            name: ''
        },
        province: {
            id: null,
            code: '',
            name: ''
        },
        city: {
            id: null,
            name: ''
        },
        postalCode: null,
        address: ''
    },
    employees: [],
    actions: false
};

export const EMPTY_LIST_COMPANY_PLACE_HOLDER: Company = {
    name: 'Nessuna società trovata corrispondente ai criteri di ricerca inseriti',
    iva: null,
    moderator: {
        name: '',
        surname: '',
        qualification: {id: null, title: '', actions: false}
    },
    headquarters: {
        region: {
            id: null,
            name: ''
        },
        province: {
            id: null,
            code: '',
            name: ''
        },
        city: {
            id: null,
            name: ''
        },
        postalCode: null,
        address: ''
    },
    employees: []
};
