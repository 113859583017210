
import { Contact } from "./contact.component";
import { Qualification } from "./qualification.component";
import { Group } from './group.component';
import {Company} from './company.component';


export const USER_TYPE = 1;

export const DIPENDENTE = 0;
export const LAVORATORE_AUTONOMO = 1;
export const FILTER_ALL = 2;

export const USER_DISABLED = 0;
export const USER_ACTIVE = 1;

export interface User {
    authToken?: string;
    ref?: string;
    surname: string;
    name?: string;
    fiscalCode?: string;
    emailAddress?: string;
    accountMode?: string;
    typeOfWorker?: number;
    qualification?: Qualification;
    associate?: boolean;
    contacts?: Contact[];
    usersGroups?: Group[];
    companies?: Company[];
    companyNames?: string[];
    groupName?: string;
    qualificationName?: string;
    actions?: boolean;
    role?: string;
    active?: number;
    groupModerator?: boolean;
}

export const EMPTY_USER_PLACE_HOLDER: User = {
    fiscalCode: null,
    surname: "Nessun utente presente",
    qualification: {id: null, title: "", actions: false}
};
export const EMPTY_LIST_USER_PLACE_HOLDER: User = {
    surname: "Nessun utente trovato corrispondente ai criteri di ricerca inseriti",
    qualification: {id: null, title: "", actions: false}
};
export const EMPTY_LIST_EMPLOYEES_PLACE_HOLDER: User = {
    surname: "Nessun dipendente presente",
    qualification: {id: null, title: "", actions: false}
};
export const EMPTY_MODERATOR_PLACE_HOLDER: User = {
    name: '',
    surname: "Nessun moderatore presente",
    qualification: {id: null, title: "", actions: false},
    emailAddress: null
};
export const EMPTY_RESPONSIBLE_PLACE_HOLDER: User = {
    fiscalCode: null,
    name: '',
    surname: "Nessun responsabile presente",
    qualification: {id: null, title: "", actions: false},
    emailAddress: null
};