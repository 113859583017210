<div id="show-test" class="page-layout blank">
    <div class="spin-loading-container" *ngIf="testService.isLoadingSomething">
        <div class="loading-wrapper" fxLayout="column">
            <mat-spinner class="loading-spinner"></mat-spinner>
            <span class="loading-text">{{testService.whatIsLoading}}</span>
        </div>
    </div>
    <mat-dialog-content fxFlexFill fxLayout="column" id="show-test-form" *ngIf="!testService.isLoadingSomething && !testService.isLoadingSomething">

        <div fxLayout="row" class="title-container dialog-title title-div">
            <mat-label fxFlex class="title">
                Visualizza test
            </mat-label>
        </div>
        <div class="card">
            <div class="card-preview">
                <div class="my-card">
                    <div fxFill fxFlex fxLayout="column">
                        <!-- upper content -->

                        <!-- card title -->
                        <div fxLayout="row" class="card-header">
                            <mat-label class="card-title">
                                Dati generali
                            </mat-label>
                        </div>

                        <div fxLayout="row" class="horizontal-container">
                            <mat-label style="padding-top: 3px">Tipologia</mat-label>
                            <mat-radio-group fxFlex fxLayout="row" disabled [(ngModel)]="responseInfo.testType">
                                <mat-radio-button [value]="1">Test di
                                    valutazione</mat-radio-button>
                                <mat-radio-button [value]="0">Sondaggio
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div fxLayout="row" class="fields-wrapper">
                            <mat-form-field class="half-field" appearance="fill">
                                <mat-label>Titolo</mat-label>
                                <input matInput disabled [(ngModel)]="responseInfo.title"/>
                            </mat-form-field>
                            <div class="right-wrapper" fxLayout="row">
                                <mat-form-field class="half-field" appearance="fill">
                                    <mat-label>Durata in giorni</mat-label>
                                    <input matInput disabled [(ngModel)]="responseInfo.validity"/>
                                </mat-form-field>
                                <mat-form-field class="half-field" appearance="fill">
                                    <mat-label>Ripeti somministrazione</mat-label>
                                    <mat-select disabled [(ngModel)]="responseInfo.automaticSharePeriod">
                                        <mat-option [value]="4">Annuale</mat-option>
                                        <mat-option [value]="0">Giornaliera</mat-option>
                                        <mat-option [value]="3">Mensile</mat-option>
                                        <mat-option [value]="5">Nessuna ripetizione</mat-option>
                                        <mat-option [value]="2">Settimanale</mat-option>
                                        <mat-option [value]="1">Tutti i giorni feriali</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" class="fields-wrapper" *ngIf="responseInfo.testType === 1">
                            <mat-form-field class="third-field" appearance="fill">
                                <mat-label>Tempo massimo in minuti</mat-label>
                                <input matInput disabled [(ngModel)]="responseInfo.maxDuration"/>
                            </mat-form-field>
                            <mat-form-field class="third-field" appearance="fill">
                                <mat-label>Numero massimo ripetizioni</mat-label>
                                <input matInput disabled [(ngModel)]="responseInfo.attempts"/>
                            </mat-form-field>
                            <mat-form-field class="third-field" appearance="fill">
                                <mat-label>Soglia minima superamento</mat-label>
                                <input matInput disabled [(ngModel)]="responseInfo.threshold"/>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" class="fields-wrapper">
                            <mat-form-field class="text-area-field full-field" appearance="fill">
                                <mat-label>Obiettivo</mat-label>
                                <textarea matInput disabled [(ngModel)]="responseInfo.goals"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="column" class="list-selector-container">
            <div fxFill fxFlex fxLayout="row">
                <div fxFlex="100" class="card">
                    <div class="card-preview">
                        <div class="my-card">
                            <div fxFill fxFlex fxLayout="column">
                                <div fxLayout="row" class="card-header">
                                    <mat-label class="card-title">
                                        Sommario
                                    </mat-label>
                                    <mat-label fxFlex class="share-date-label">
                                        {{"Data di somministrazione: " + selectedShareDateLabel}}
                                    </mat-label>
                                </div>
                                <div fxFlex fxLayout="column" class="table-container" [ngClass]="{'table-container-scroll' : filledTestBaseData.length > 20}">
                                    <table mat-table [dataSource]="filledTests" class="mat-elevation-z8 table-style">
                                        <ng-container matColumnDef="company">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Società
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ ellipsify(element.targetUser.companyNames?.toString()) || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="group">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Gruppo
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.targetUser.group?.name || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="qualification">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Qualifica
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.targetUser.qualification }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="surname">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Cognome
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.targetUser.surname }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Nome
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.targetUser.name }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="fiscalCode">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Codice fiscale
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.targetUser.fiscalCode }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="result" *ngIf="responseInfo.testType === 1">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Risultato
                                            </th>
                                            <td mat-cell *matCellDef="let element" [matTooltipDisabled]="!element.done"
                                                class="score-button" matTooltip="Mostra dettaglio test"
                                                [matTooltipPosition]="toolTipPosition" [matTooltipShowDelay]="500"
                                                matTooltipClass="custom-tooltip">
                                                <a (click)="showDetails(element)"
                                                   *ngIf="element.done && totalQuestionsCount != undefined
                                                    && totalQuestionsCount != null && element.targetUser.bestResult != undefined">
                                                    {{ element.targetUser.bestResult.result  }} / {{ totalQuestionsCount }}
                                                </a>
                                                <div *ngIf="!element.done"> - </div>
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="date">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Data esecuzione
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ datePipe.transform(element.targetUser.executionDate, 'dd/MM/yyyy') || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="outcome">
                                            <th mat-header-cell *matHeaderCellDef>
                                                {{responseInfo.testType === 1? "Esito" : "Dettagli" }}
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <a (click)="showDetails(element)"
                                                   *ngIf="responseInfo.testType === 0 && element.done">
                                                    <mat-icon style="padding-top: 3px;"> info </mat-icon>
                                                </a>
                                                <div *ngIf="!element.done && responseInfo.testType === 0"> - </div>
                                                {{  responseInfo.testType === 1? (element.outcome || '-') : null}}
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row class="table-header" *matHeaderRowDef="
                                                testReplyColumnNames;
                                                sticky: true
                                            "></tr>
                                        <tr mat-row *matRowDef="
                                                let row;
                                                columns: testReplyColumnNames
                                            "></tr>
                                    </table>

                                </div>
                                <div fxLayout="row" class="page-nav-buttons-container">
                                    <button [disabled]="selectedShareDate + 1 == shareDatesCount" mat-raised-button class="prev-button" color="accent" (click)="switchDate(selectedShareDate + 1)">
                                        Precedente somministrazione
                                    </button>
                                    <button [disabled]="selectedShareDate <= 0" mat-raised-button color="accent" class="next-button"
                                            (click)="switchDate(selectedShareDate - 1)">Successiva somministrazione</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" class="buttons-container" *ngIf="!testService.isLoadingSomething">
        <button mat-raised-button color="accent" class="confirm-button" (click)="exportExcel()"
                *ngIf="userService.loggedUser.role === 'AMMINISTRATORE' ||
                            userService.loggedUser.role === 'RBAC_ADMIN'">
            Esporta
        </button>
        <button mat-raised-button class="confirm-button" style="visibility: hidden"
                *ngIf="!(userService.loggedUser.role === 'AMMINISTRATORE' ||
                            userService.loggedUser.role === 'RBAC_ADMIN')">

        </button>
        <button mat-raised-button color="accent" class="cancel-button exit-button"
                (click)="closeDialog(true)">
            Esci
        </button>
    </mat-dialog-actions>
</div>
