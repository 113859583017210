import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenManagerService} from './token-manager.service';
import {LoadingInfo} from './loading-info/loading-info';
import {Test} from '../entity/test.component';
import {Endpoints} from '../apps/utils/Endpoints';
import {TEST_SORT_BY_SHARE_DATE, TEST_SORT_BY_TITLE, TEST_SORT_BY_TYPE} from '../apps/utils/commons.component';

@Injectable({
    providedIn: 'root'
})
export class TestService extends LoadingInfo {

    constructor(private _httpClient: HttpClient, private _tokenManagerService: TokenManagerService) {
        super();
    }

    public async shareTest(test: Test) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento informazioni test';
        let promise;

        const body = {
            authToken: this._tokenManagerService.authToken,
            test: {
                title: test.title,
                goals: test.goals,
                testType: test.testType,
                validity: test.validity,
                threshold: test.threshold,
                maxDuration: test.maxDuration,
                attempts: test.attempts,
                automaticSharePeriod: test.shareTime
            },
            testTarget: {
                workerType: test.targetWorkerType,
                associatesOnly: test.targetOnlyAssociates,
                qualifications: test.targetQualifications,
                personalUsers: test.targetUsers,
                usersGroups: test.targetGroups,
                companies: test.targetCompanies
            },
            questions: test.questions
        };

        await this._httpClient.post(
            Endpoints.SUBMIT_TEST, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async getTestsByPage(page: number, sortType: string, sortBy: number, pageSize: number, filter?: any) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento test';
        let promise;
        let body;
        let sortValue;

        switch (sortBy) {
            case TEST_SORT_BY_TITLE:
                sortValue = 'title';
                break;
            case TEST_SORT_BY_TYPE:
                sortValue = 'type';
                break;
            case TEST_SORT_BY_SHARE_DATE:
                sortValue = 'share_date';
                break;
            default:
                sortValue = 'title';
        }


        if(filter != null && filter != undefined) {
            body = {
                authToken: this._tokenManagerService.authToken,
                page: page,
                sortType: sortType,
                sortBy: sortValue,
                pageSize: pageSize,
                filter: filter
            };
        }else {
            body = {
                authToken: this._tokenManagerService.authToken,
                page: page,
                sortType: sortType,
                sortBy: sortValue,
                pageSize: pageSize
            };
        }

        await this._httpClient.post(
            Endpoints.GET_TESTS_BY_PAGE, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async deleteTest(testId: number) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Eliminazione test';
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken,
            id: testId
        };

        await this._httpClient.post(
            Endpoints.DELETE_TEST, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async toggleAutoShareOff(testId: number) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Disabilitazione auto-somministrazione';
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken,
            id: testId
        };

        await this._httpClient.post(
            Endpoints.DISABLE_AUTO_SHARE, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async getTestInfo(testId: number, dateIndex: number) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento test';
        let promise;
        let body;
            body = {
                authToken: this._tokenManagerService.authToken,
                testId: testId,
                dateIndex: dateIndex
            };

            await this._httpClient.post(
            Endpoints.GET_TEST_INFO, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async getUserReplies(testId: number, userRef: string, shareDate: string) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento risposte';

        let promise;
        let body;
            body = {
                authToken: this._tokenManagerService.authToken,
                testId: testId,
                userRef: userRef,
                shareDate: shareDate
            };

        await this._httpClient.post(
            Endpoints.GET_USER_REPLIES, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async exportTest(testId: number) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Esportazione test';
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken,
            testId
        };

        await this._httpClient.post(
            Endpoints.EXPORT_TEST, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json'),
                responseType: 'blob',
                reportProgress: true
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }
}

