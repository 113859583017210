import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    Inject,
} from "@angular/core";
import {
    MatDialogRef,
    MatDialogConfig,
    MatDialog,
} from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
    User
} from "../../../../entity/user.component";
import {
    Contact,
    EMPTY_CONTACT_PLACE_HOLDER,
    CONTACT_TYPE,
} from "../../../../entity/contact.component";
import {
    Group,
    EMPTY_GROUP_PLACE_HOLDER,
    GROUP_TYPE,
} from "app/main/entity/group.component";
import { TooltipPosition } from "@angular/material/tooltip";
import {
    CONFIRM_OPTIONS,
    ConfirmDialogModule,
} from "app/main/apps/utils/confirm-dialog/confirm-dialog.component";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import {
    ASCII_CAPITAL_CHAR_HIGHER_BOUND,
    ASCII_CAPITAL_CHAR_LOWER_BOUND,
    ASCII_CHAR_LOWER_BOUND,
    ASCII_CHAR_HIGHER_BOUND,
    ASCII_BACKSPACE,
    ASCII_SPACE,
    ASCII_NUMBER_LOWER_BOUND,
    ASCII_NUMBER_HIGHER_BOUND,
    METHOD_NOT_ALLOWED,
    STATUS_UNAUTHORIZED,
    GENERAL_NAME_REGEX,
    FISCAL_CODE_REGEX,
    EMAIL_REGEX,
    ASCII_ACCENTED_CHARACTER_LOWER_BOUND,
    ASCII_ACCENTED_CHARACTER_HIGHER_BOUND,
    ASCII_APOSTROPHE,
    PHONE_NUMBERS_ONLY_REGEX, CONFLICT
} from '../../../utils/commons.component';
import {UserService} from '../../../../services/user.service';
import {GroupService} from '../../../../services/group.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ToastManager} from '../../../utils/toast-manager';
import {Qualification} from '../../../../entity/qualification.component';
import {Company, COMPANY_TYPE, EMPTY_COMPANIES_PLACE_HOLDER} from '../../../../entity/company.component';
import {CompanyService} from '../../../../services/company.service';

@Component({
    selector: "user",
    templateUrl: "./show-user-dialog.component.html",
    styleUrls: ["./show-user-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ShowUserDialog extends ToastManager implements OnInit, OnDestroy {
    toolTipPosition: TooltipPosition;
    contactsColumnsNames: string[];
    contactsStructure: Contact[];
    contacts: MatTableDataSource<Contact>;

    groupsColumns: string[];
    groupsBaseData: Group[];
    availableCompanies: Company[];
    groups: MatTableDataSource<Group>;

    companyColumns: string[];
    companiesData: Company[] = [];
    companies: MatTableDataSource<Company>;

    userRef: string;
    userEmail: string;
    userRole: string;
    errorMessage: string;
    emptyError: boolean;
    formatError: boolean;
    isCompanyMandatory: boolean;
    isCompanyModerator: boolean;
    isGroupModerator: boolean;
    canBeSelfEmployed: boolean = true;

    editUserForm: FormGroup;
    selectedCompaniesForm: FormGroup;
    validationSubscription: Subscription;
    contactsForm: FormGroup;
    addGroupForm: FormGroup;
    availableGroups: Group[];
    qualifications: Qualification[];

    constructor(
        private dialogRef: MatDialogRef<ShowUserDialog>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public userData: any,
        public companyService: CompanyService,
        public userService: UserService,
        public groupService: GroupService,
        private _sBar: MatSnackBar
    ) {
        super(_sBar);
        this.availableCompanies = [];
        this.groupsBaseData = [];
        this.contactsStructure = [];
        this.availableGroups = [];
        this.isCompanyModerator = false;
        this.isCompanyMandatory = true;
        this.toolTipPosition = "above";
        this.contacts = new MatTableDataSource<Contact>();
        this.companies = new MatTableDataSource<Company>();
        this.groups = new MatTableDataSource<Group>();

        this.userService.getUserQualifications().then((qualifications) => {
            this.qualifications = qualifications;
            this.companyService.getCompanyNames().then((result) => {
                this.companyService.isLoadingSomething = false;
                result['companies'].forEach(company => {
                    let newCompany: Company = {
                        name: company.name,
                        id: company.companyId,
                        iva: null,
                        moderator: {
                            name: '',
                            surname: '',
                            qualification: {id: null, title: '', actions: false}
                        },
                        headquarters: {
                            region: {
                                id: null,
                                name: ''
                            },
                            province: {
                                id: null,
                                code: '',
                                name: ''
                            },
                            city: {
                                id: null,
                                name: ''
                            },
                            postalCode: null,
                            address: ''
                        },
                    }
                    this.availableCompanies.push(newCompany);
                });
                if (this.availableCompanies.length === 0) {
                    this.availableCompanies =[EMPTY_COMPANIES_PLACE_HOLDER];
                }
            }).catch((error) => {
                this.companyService.isLoadingSomething = false;
                if (this.availableCompanies.length === 0) {
                    this.availableCompanies = [EMPTY_COMPANIES_PLACE_HOLDER];
                }
            })
            this.loadUserInfo(this.userData.user.fiscalCode);
        });

        this.editUserForm = new FormGroup({
            name: new FormControl("", [Validators.required, Validators.pattern(GENERAL_NAME_REGEX), Validators.maxLength(30)]),
            surname: new FormControl("", [Validators.required, Validators.pattern(GENERAL_NAME_REGEX), Validators.maxLength(30)]),
            fiscalCode: new FormControl("", [
                Validators.required,
                Validators.pattern(
                    FISCAL_CODE_REGEX
                ),
            ]),
            emailAddress: new FormControl("", [
                Validators.required,
                Validators.pattern(EMAIL_REGEX)
            ]),
            accountMode: new FormControl("", [
                Validators.required,
            ]),
            workerType: new FormControl(null, [
                Validators.required,
            ]),
            qualification: new FormControl("", [
                Validators.required,
            ]),
            isAssociated: new FormControl(false, [
                Validators.required,
            ]),
        });

        this.contactsForm = new FormGroup({
            type: new FormControl("", [Validators.required]),
            contact: new FormControl("", [Validators.required, Validators.pattern(PHONE_NUMBERS_ONLY_REGEX)]),
        });

        this.addGroupForm = new FormGroup({
            id: new FormControl('', [Validators.required])
        });

        this.selectedCompaniesForm = new FormGroup({
            selectedCompany: new FormControl(null, Validators.required)
        });
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.validationSubscription.unsubscribe();
    }

    protected deleteContact(contact: String): void {
        for (let i = 0; i < this.contactsStructure.length; i++) {
            if (this.contactsStructure[i].number == contact) {
                this.contactsStructure.splice(i, 1);
                break;
            }
        }
        this.checkEmptyTables(CONTACT_TYPE);
        this.updateTable(CONTACT_TYPE);
    }
    protected deleteGroup(name: String): void {
        for (let i = 0; i < this.groupsBaseData.length; i++) {
            if (this.groupsBaseData[i].name == name) {
                this.groupsBaseData.splice(i, 1);
                break;
            }
        }
        this.checkEmptyTables(GROUP_TYPE);
        this.updateTable(GROUP_TYPE);
    }

    protected deleteCompany(company: Company): void {
        this.companiesData.splice(this.companiesData.indexOf(company), 1);
        this.updateGroupsBasedOnDeletedCompany(company);
        this.checkEmptyTables(COMPANY_TYPE);
        this.updateTable(COMPANY_TYPE);
    }

    private updateGroupsBasedOnDeletedCompany(deletedCompany: Company): void {
        this.availableGroups = this.availableGroups.filter((group) => group.companyId !== deletedCompany.id);
        this.groupsBaseData = this.groupsBaseData.filter((group) => group.companyId !== deletedCompany.id);

        if (this.availableGroups.length === 0) {
            this.availableGroups = [EMPTY_GROUP_PLACE_HOLDER];
        }

        if (this.groupsBaseData.length === 0) {
            this.groupsBaseData = [EMPTY_GROUP_PLACE_HOLDER];
        }
        this.updateTable(GROUP_TYPE);
    };

    private checkDuplicateContact(type: String, contact: String): boolean {
        return (this.contactsStructure.filter((contactToCheck) => (contactToCheck.number === contact && contactToCheck.type === type)).length > 0);
    }

    public addContact(): void {
        this.contactsForm.markAllAsTouched();
        if (
            this.contactsForm.get('contact').hasError('required') ||
            this.contactsForm.get('type').hasError('required')
        ) {
            this.errorMessage = 'Attenzione! Campi obbligatori non compilati.';
            return;
        } else if (this.contactsForm.get('contact').hasError('pattern')) {
            this.errorMessage = 'Attenzione! Campo/i non popolati correttamente.';
            return;
        }

        if (this.checkDuplicateContact(this.contactsForm.get('type').value, this.contactsForm.get('contact').value)) {
            this.errorMessage = 'Attenzione! Recapito già presente.';
            return;
        }

        this.checkEmptyTables(CONTACT_TYPE);
        const _toAdd: Contact = {
            type: this.contactsForm.get("type").value,
            number: this.contactsForm.get("contact").value,
            actions: true,
        };
        this.contactsStructure.push(_toAdd);
        this.updateTable(CONTACT_TYPE);
        this.errorMessage = "";
        this.contactsForm.reset();
    }

    private checkDuplicateGroup(id: number): boolean {
        return (this.groupsBaseData.filter((group) => (group.id === id)).length > 0);
    }

    public addGroup(): void {
        this.addGroupForm.markAllAsTouched();
        if (this.addGroupForm.get('id').value == '' || this.addGroupForm.get('id').value == null) {
            this.errorMessage = 'Attenzione! Campi obbligatori non compilati.';
            return;
        }
        if (this.checkDuplicateGroup(this.addGroupForm.get('id').value)) {
            this.errorMessage = 'Attenzione! Gruppo già presente.';
            return;
        }
        this.checkEmptyTables(GROUP_TYPE);
        this.availableGroups.forEach(group => {
            if (this.addGroupForm.get('id').value === group.id) {
                group.actions = true;
                this.groupsBaseData.push(group);
            }
        })
        
        this.groupsBaseData.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        this.addGroupForm.reset();
        this.errorMessage = "";
        this.updateTable(GROUP_TYPE);
    }

    /***** execute the update on the dynamic table data *****/
    private updateTable(type: Number): void {
        /***** Update contacts *****/
        if (type == CONTACT_TYPE) {
            this.contacts.data = this.contactsStructure;
        } else if (type == GROUP_TYPE) {
            /***** Update groups *****/
            this.groups.data = this.groupsBaseData;
        } else {
            this.companies.data = this.companiesData;
        }
    }

    public addCompany(): void {
        this.selectedCompaniesForm.markAllAsTouched();

        if (this.selectedCompaniesForm.get('selectedCompany').value === null ||
            this.selectedCompaniesForm.get('selectedCompany').value === '') {
            this.errorMessage = 'Attenzione! Campi obbligatori non compilati.';
            return;
        }

        this.loadGroups(this.selectedCompaniesForm.get('selectedCompany').value);
        const _toAdd: Company = {
            name: this.selectedCompaniesForm.get('selectedCompany').value.name,
            id: this.selectedCompaniesForm.get('selectedCompany').value.id,
            actions: true,
            iva: null,
            moderator: null,
            headquarters: null,
            employees: []
        };

        this.companiesData.push(_toAdd);
        this.checkEmptyTables(COMPANY_TYPE);
        this.companiesData.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        this.selectedCompaniesForm.reset();
        this.updateTable(COMPANY_TYPE);
        this.errorMessage = '';
    };

    public loadGroups(company: Company) {
        if (company === null) {
            if (this.availableGroups.length === 0) {
                this.availableGroups = [EMPTY_GROUP_PLACE_HOLDER];
            }
        } else {
            this.companyService.getCompanyGroups(company.id).then(result => {
                if (this.availableGroups.length === 1 && this.availableGroups[0] === EMPTY_GROUP_PLACE_HOLDER) {
                    this.availableGroups = [];
                }
                result['companyGroups'].forEach((group: Group) => {
                    group.companyId = company.id;
                    group.companyName = company.name;
                    this.availableGroups.push(group);
                });
                this.availableGroups.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                if (this.availableGroups.length === 0) {
                    this.availableGroups = [EMPTY_GROUP_PLACE_HOLDER];
                }
            }).catch(() => {
                if (this.availableGroups.length === 0) {
                    this.availableGroups = [EMPTY_GROUP_PLACE_HOLDER];
                }
            });
        }
    }

    private checkEmptyTables(type: Number): void {
        if (type == CONTACT_TYPE) {
            if (this.contactsStructure.length <= 0) {
                this.contactsStructure.push(EMPTY_CONTACT_PLACE_HOLDER);
                this.updateTable(CONTACT_TYPE);
            } else if (!this.contactsStructure[0].actions) {
                this.contactsStructure.splice(0, 1);
            }
        } else if (type == GROUP_TYPE) {
            if (this.groupsBaseData.length <= 0) {
                this.groupsBaseData.push(EMPTY_GROUP_PLACE_HOLDER);
                this.updateTable(GROUP_TYPE);
            } else if (!this.groupsBaseData[0].actions) {
                this.groupsBaseData.splice(0, 1);
            }
        } else if (type == COMPANY_TYPE) {
            if (this.companiesData.length <= 0) {
                this.companiesData.push(EMPTY_COMPANIES_PLACE_HOLDER);
                this.updateTable(COMPANY_TYPE);
            } else if (!this.companiesData[0].actions) {
                this.companiesData.splice(0, 1);
            }
        }
    }

    private packUser(): User {
        /**** updating data to return ****/
        let localUser: User = {
            surname: this.editUserForm.get("surname").value,
            name: this.editUserForm.get("name").value,
            fiscalCode: this.userRef !== this.editUserForm.get("fiscalCode").value? this.editUserForm.get("fiscalCode").value : null,
            emailAddress: this.userEmail !== this.editUserForm.get("emailAddress").value? this.editUserForm.get("emailAddress").value : null,
            role: this.editUserForm.get("accountMode").value,
            typeOfWorker: this.editUserForm.get("workerType").value,
            associate: Boolean(this.editUserForm.get("isAssociated").value),
            qualification: {
                id: this.editUserForm.get("qualification").value,
                title: this.qualifications[this.qualifications.
                findIndex(qualification => qualification.id === this.editUserForm.get("qualification").value)].title,
            },
            companies: this.companies.data,
            contacts: this.contactsStructure.concat(),
            usersGroups: this.groupsBaseData.concat(),
            actions: true,
        };
        if (!localUser.contacts[0].actions) {
            localUser.contacts.splice(0, 1);
        }
        if (!localUser.usersGroups[0].actions) {
            localUser.usersGroups.splice(0, 1);
        }
        return localUser;
    }

    private checkErrors(): void {
        this.editUserForm.markAllAsTouched();

        this.emptyError = this.editUserForm.get("name").hasError("required") ||
            this.editUserForm.get("surname").hasError("required") ||
            this.editUserForm.get("fiscalCode").hasError("required") ||
            this.editUserForm.get("emailAddress").hasError("required") ||
            this.editUserForm.get("accountMode").hasError("required") ||
            this.editUserForm.get("workerType").hasError("required") ||
            this.editUserForm.get("isAssociated").hasError("required") ||
            this.editUserForm.get("qualification").hasError("required") ||
            (this.selectedCompaniesForm.enabled && (this.companies.data.length === 1 && this.companies.data[0].id === null));

        this.formatError = this.editUserForm.get('emailAddress').hasError('pattern') ||
            this.editUserForm.get('fiscalCode').hasError('pattern') ||
            this.editUserForm.get('name').hasError('pattern') ||
            this.editUserForm.get('surname').hasError('pattern') ||
            this.editUserForm.get('name').hasError('maxlength') ||
            this.editUserForm.get('surname').hasError('maxlength');
    }

    /***** Save changes to the db *****/
    saveChanges() {
        this.checkErrors();
        if (this.emptyError) {
            this.errorMessage = "Attenzione! Campi obbligatori non compilati.";
        } else if (this.formatError) {
            this.errorMessage =
                "Attenzione! Campo/i non popolati correttamente.";
        }

        if (!this.emptyError && !this.formatError) {
            let updatedUser = this.packUser();
            let message: string = "";
            this.userService.updateUser(updatedUser, this.userRef).then(result => {
                if(result['status'] === true) {
                    /**** closing the popup and giving back edited data *****/
                    message = "Utente modificato correttamente";
                    updatedUser.fiscalCode = updatedUser.fiscalCode? updatedUser.fiscalCode : this.userRef;
                    updatedUser.emailAddress = updatedUser.emailAddress? updatedUser.emailAddress : this.userEmail;
                    this.dialogRef.close(updatedUser);
                } else if(result['status'] === CONFLICT) {
                    this.errorMessage = result['error']['result'];
                } else if(result['status'] === METHOD_NOT_ALLOWED || result['status'] === STATUS_UNAUTHORIZED){
                    message = result['error']['result'];
                }
                this.showToast(message);
            });
            this.emptyError = false;
            this.formatError = false;
        }
    }

    /***** at the cancel click close the popup *****/
    closePopup(): void {
        this.dialogRef.close();
    }

    showConfirmPopup(element: any, mode: number): void {
        /**** defining dialog configuration *****/
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;

        /**** defining popup content ****/
        if (mode == CONFIRM_OPTIONS.RM_CONTACT) {
            dialogConfig.data = {
                content:
                    'Sei sicuro di voler eliminare\nil recapito telefonico \n' +
                    element +
                    '?',
                operationType: mode,
            };
        } else if (mode == CONFIRM_OPTIONS.RM_GROUP) {
            dialogConfig.data = {
                content:
                    'Sei sicuro di voler deallocare\nl\'utente dal gruppo \n' +
                    element +
                    '?',
                operationType: mode,
            };
        } else if (mode == CONFIRM_OPTIONS.RM_COMPANY) {
            dialogConfig.data = {
                content:
                    'Sei sicuro di voler rimuovere\n la società \n' +
                    element.name +
                    '?',
                operationType: mode,
            };
        }

        /**** opening dialog with desired config *****/
        const dialogRef = this.dialog.open(ConfirmDialogModule, dialogConfig);

        /***** registering to the user reply ****/
        dialogRef.afterClosed().subscribe((response) => {
            if (response.reply) {
                if (response.type == CONFIRM_OPTIONS.RM_CONTACT) {
                    this.deleteContact(element);
                } else if (response.type == CONFIRM_OPTIONS.RM_GROUP) {
                    this.deleteGroup(element);
                } else if (response.type == CONFIRM_OPTIONS.RM_COMPANY) {
                    this.deleteCompany(element);
                }
            }
        });
    }

    public textOnly(event) {
        var k;
        k = event.charCode;
        return (k > ASCII_CAPITAL_CHAR_LOWER_BOUND && k < ASCII_CAPITAL_CHAR_HIGHER_BOUND) ||
            (k > ASCII_CHAR_LOWER_BOUND && k < ASCII_CHAR_HIGHER_BOUND) ||
            (k > ASCII_ACCENTED_CHARACTER_LOWER_BOUND && k < ASCII_ACCENTED_CHARACTER_HIGHER_BOUND) ||
            k == ASCII_BACKSPACE || k == ASCII_SPACE  || k == ASCII_APOSTROPHE;
    }

    public numbersOnly(event) {
        var k;
        k = event.charCode;
        return (k > ASCII_NUMBER_LOWER_BOUND && k < ASCII_NUMBER_HIGHER_BOUND) ||
            k == ASCII_BACKSPACE ||
            k == ASCII_SPACE;
    }

    public onPasteTextOnly(event: ClipboardEvent) {
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        if (!pastedText.match(GENERAL_NAME_REGEX)) {
            this.errorMessage = 'Attenzione! Campo/i non popolati correttamente.';
            event.preventDefault();
        }
    }

    public onPasteNumbersOnly(event: ClipboardEvent) {
        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text');
        if (!pastedText.match(PHONE_NUMBERS_ONLY_REGEX)) {
            this.errorMessage = 'Attenzione! Campo/i non popolati correttamente.';
            event.preventDefault();
        }
    }

    private initComponents(user: User) {
        /**** init components *****/
        this.onSelectRole(user.role);
        this.isGroupModerator = user.groupModerator;
        this.groupsBaseData = user.usersGroups;
        this.contactsStructure = user.contacts;
        this.companiesData = user.companies;

        for(let contact of this.contactsStructure) {
            contact.actions = true;
        }

        for(let group of this.groupsBaseData) {
            group.actions = true;
        }

        for (let company of this.companiesData) {
            this.loadGroups(company);
            company.actions = true;
        }

        if (this.companiesData.length > 0 && user.role == "MODERATORE") {
            this.isCompanyModerator = true;
        }

        this.editUserForm.get("workerType").setValue(user.typeOfWorker);
        this.editUserForm.get("name").setValue(user.name);
        this.editUserForm.get("surname").setValue(user.surname);
        this.editUserForm.get("fiscalCode").setValue(user.fiscalCode);
        this.editUserForm.get("emailAddress").setValue(user.emailAddress);
        this.editUserForm.get("qualification").setValue(user.qualification.id);
        this.editUserForm.get("isAssociated").setValue(user.associate);

        this.userRef = user.fiscalCode;
        this.userEmail = user.emailAddress;
        this.userRole = user.role;

        this.emptyError = false;
        this.formatError = false;
        this.errorMessage = "";

        if (this.userData.editMode) {
            this.contactsColumnsNames = ["type", "contact", "actions"];
            this.groupsColumns = ["company", "group", "actions"];
            this.companyColumns = ["company", "actions"];
        } else {
            this.contactsColumnsNames = ["type", "contact"];
            this.groupsColumns = ["company", "group"];
            this.companyColumns = ["company"];
        }

        if (!this.userData.editMode) {
            this.editUserForm.disable();
        }
        /***** first check for eventually empty tables *****/
        this.checkEmptyTables(GROUP_TYPE);
        this.checkEmptyTables(CONTACT_TYPE);
        this.checkEmptyTables(COMPANY_TYPE);

        this.updateTable(CONTACT_TYPE);
        this.updateTable(GROUP_TYPE);
        this.updateTable(COMPANY_TYPE);
        this.validationSubscription = this.editUserForm.statusChanges.subscribe(
            (val) => {
                if (val == "VALID") {
                    this.errorMessage = "";
                }
            }
        );
        setTimeout((_) => this.editUserForm.updateValueAndValidity());
    }

    private loadUserInfo(fiscalCode: string) {
        this.userService.getUserInfo(fiscalCode).then(result => {
            let message: string = ""
            if(result['status'] === true) {
                this.initComponents(result['user']);
            } else if (result['status'] === CONFLICT) {
                this.errorMessage = result['error']['result']
            } else if(result['status'] === METHOD_NOT_ALLOWED || result['status'] === STATUS_UNAUTHORIZED) {
                message = result['error']['result'];
            } else{
                message = result['error']['result'];
            }
            this.showToast(message);
        });
    }

    onSelectRole(role: string) {
        this.selectedCompaniesForm.get('selectedCompany').setValue(null);
        this.editUserForm.get('workerType').enable();
        this.editUserForm.get("accountMode").setValue(role);
        if (role === 'AMMINISTRATORE' || role === 'MODERATORE') {
            this.addGroupForm.disable();
            this.selectedCompaniesForm.get('selectedCompany').disable();
            this.isCompanyMandatory = true;
            this.canBeSelfEmployed = false;
            this.editUserForm.get('workerType').setValue(0);
            this.onSelectTypeChange();
        } else {
            if (role === 'RESPONSABILE') {
                this.canBeSelfEmployed = false;
                this.editUserForm.get('workerType').setValue(0);
            } else {
                this.canBeSelfEmployed = true;
                this.editUserForm.get('workerType').setValue(null);
            }
            if (this.companiesData.length > 1) {
                this.onSelectTypeChange();
            }
            this.addGroupForm.enable();
            this.selectedCompaniesForm.get('selectedCompany').enable();
            this.selectedCompaniesForm.get('selectedCompany').setValidators([Validators.required]);
            this.isCompanyMandatory = true;
            this.userRole = role;
        }
    }

    isOptionAlreadySelected(type: number, option: any): boolean {
        if (type === COMPANY_TYPE) {
            return this.companiesData.filter((company) => company.id === option.id).length > 0;
        } else if (type === GROUP_TYPE) {
            return this.groupsBaseData.filter((group) => group.id === option.id).length > 0;
        }
    };

    onSelectTypeChange() {
        this.companiesData = [EMPTY_COMPANIES_PLACE_HOLDER];
        this.companies.data = this.companiesData;
        this.groupsBaseData = [EMPTY_GROUP_PLACE_HOLDER];
        this.groups.data = this.groupsBaseData;
        this.availableGroups = [EMPTY_GROUP_PLACE_HOLDER];
    }
}
