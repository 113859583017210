import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';

import {EMPTY_USER_PLACE_HOLDER, User, USER_TYPE,} from '../../../../entity/user.component';
import {
    EMPTY_READ_CONFIRM_PLACEHOLDER,
    READING_CONFIRM_TYPE,
    ReadingConfirm,
} from '../../../../entity/reading-confirm.component';
import {
    EMPTY_QUALIFICATION_PLACE_HOLDER,
    Qualification,
    QUALIFICATION_TYPE,
} from '../../../../entity/qualification.component';
import {EMPTY_GROUP_PLACE_HOLDER, Group, GROUP_TYPE,} from '../../../../entity/group.component';
import {DocumentsService} from '../../../../services/documents.service';
import {
    CONFLICT,
    METHOD_NOT_ALLOWED,
    STATUS_UNAUTHORIZED,
} from '../../../utils/commons.component';
import {
    ALTRO,
    CDA_ASSEMBLEA,
    COMUNICAZIONI,
    CONTABILITA,
    PRIVACY,
    SICUREZZA
} from '../../../../entity/document.component';
import {TooltipPosition} from '@angular/material/tooltip';
import {ToastManager} from '../../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FileService} from '../../../../services/file.service';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

@Component({
    selector: 'document',
    templateUrl: './show-document-dialog.component.html',
    styleUrls: ['./show-document-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ShowDocumentDialog extends ToastManager implements OnInit {
    toolTipPosition: TooltipPosition;
    title: string;
    shareDate: string;
    filePath: string;
    description: string;
    type: string;
    id: number;
    typeOfWorker: string;
    onlyAssociates: number;

    privUsersBaseData: User[];
    privUsers: MatTableDataSource<User>;

    qualificationBaseData: Qualification[];
    qualifications: MatTableDataSource<Qualification>;

    groupsBaseData: Group[];
    groups: MatTableDataSource<Group>;

    readingUsersBaseData: ReadingConfirm[];
    readingUsers: MatTableDataSource<ReadingConfirm>;

    usersColumnsNames: string[];
    qualificationsColumnsNames: string[];
    groupsColumnsNames: string[];
    readingConfirmationColNames: string[];

    constructor(
        private dialogRef: MatDialogRef<ShowDocumentDialog>,
        @Inject(MAT_DIALOG_DATA) public documentData: any,
        public documentsService: DocumentsService,
        private _dialog: MatDialog,
        private _sBar: MatSnackBar,
        private _fileService: FileService
    ) {
        super(_sBar);
        this.toolTipPosition = 'above';
        this.title = documentData.title;
        this.type = this.setType(documentData.type);
        /**** Initialization ****/
        this.onlyAssociates = null;
        this.shareDate = documentData.shareDate;
        this.id = null;
        this.privUsersBaseData = [];
        this.qualificationBaseData = [];
        this.groupsBaseData = [];
        this.readingUsersBaseData = [];
        this.usersColumnsNames = ['surname', 'name'];
        this.qualificationsColumnsNames = ['group'];
        this.groupsColumnsNames = ['group'];
        this.readingConfirmationColNames = [
            'company',
            'group',
            'qualification',
            'surname',
            'name',
            'fiscalCode',
            'read'
        ];

        this.privUsers = new MatTableDataSource<User>();
        this.qualifications = new MatTableDataSource<Qualification>();
        this.groups = new MatTableDataSource<Group>();
        this.readingUsers = new MatTableDataSource<ReadingConfirm>();

        this.getDocumentDetails(documentData.filePath);

    }

    ngOnInit(): void {
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    private updateTable(type: number): void {
        /***** Update users *****/
        if (type == USER_TYPE) {
            this.privUsers.data = this.privUsersBaseData;
        } else if (type == QUALIFICATION_TYPE) {
            /***** Update qualification *****/
            this.qualifications.data = this.qualificationBaseData;
        } else if (type == GROUP_TYPE) {
            /***** Update gruppi *****/
            this.groups.data = this.groupsBaseData;
        } else if (type == READING_CONFIRM_TYPE) {
            /***** Update reading users *****/
            this.readingUsers.data = this.readingUsersBaseData;
        }
    }

    private checkEmptyTables(type: number): void {
        /***** Check if contacts table is empty *****/
        if (this.privUsersBaseData.length <= 0) {
            this.privUsersBaseData.push(EMPTY_USER_PLACE_HOLDER);
            this.updateTable(USER_TYPE);
        } else if (!this.privUsersBaseData[0].actions) {
            /***** Check if contacts table has the placeholder *****/
            if (type == USER_TYPE) {
                this.privUsersBaseData.splice(0, 1);
            }
        }
        if (this.qualificationBaseData.length <= 0) {
            /***** Check if qualification table is empty *****/
            this.qualificationBaseData.push(EMPTY_QUALIFICATION_PLACE_HOLDER);
            this.updateTable(QUALIFICATION_TYPE);
        } else if (!this.qualificationBaseData[0].actions) {
            /***** Check if qualification table has the placeholder *****/
            if (type == QUALIFICATION_TYPE) {
                this.qualificationBaseData.splice(0, 1);
            }
        }
        if (this.groupsBaseData.length <= 0) {
            /***** Check if group table is empty *****/
            this.groupsBaseData.push(EMPTY_GROUP_PLACE_HOLDER);
            this.updateTable(GROUP_TYPE);
        } else if (!this.groupsBaseData[0].actions) {
            /***** Check if group table has the placeholder *****/
            if (type == GROUP_TYPE) {
                this.groupsBaseData.splice(0, 1);
            }
        }
        if (this.readingUsersBaseData.length <= 0) {
            /***** Check if reading users table is empty *****/
            this.readingUsersBaseData.push(EMPTY_READ_CONFIRM_PLACEHOLDER);
            this.updateTable(READING_CONFIRM_TYPE);
        } else if (this.readingUsersBaseData[0].user.surname == '') {
            /***** Check if reading users table has the placeholder *****/
            if (type == READING_CONFIRM_TYPE) {
                this.readingUsersBaseData.splice(0, 1);
            }
        }
    }

    private getDocumentDetails(docId: string) {
        this.documentsService.getDocumentDetails(docId).then(result => {
            let message: string = '';
            if (result['status'] === true) {
                this.setupDocumentInfo(result['documentInfo']);
                this.setupDocumentTargets(result['documentTargetsInfo']);
                this.setupDocumentShares(result['documentSharingInfo']);

                this.checkEmptyTables(null);
                this.updateTable(USER_TYPE);
                this.updateTable(GROUP_TYPE);
                this.updateTable(QUALIFICATION_TYPE);
                this.updateTable(READING_CONFIRM_TYPE);
            } else if (result['status'] === METHOD_NOT_ALLOWED || result['status'] === STATUS_UNAUTHORIZED || result['status'] === CONFLICT) {
                message = result['error']['result'];
            } else {
                message = result['error']['result'];
            }
            this.showToast(message);
        });
    }

    private setupDocumentInfo(documentInfo: any) {
        this.title = documentInfo['title'];
        this.description = documentInfo['description'];
        this.filePath = documentInfo['filePath'];
        this.id = documentInfo['id'];
    }

    private setupDocumentTargets(documentTarget: any) {
        this.typeOfWorker = documentTarget['workerType'];
        this.onlyAssociates = documentTarget['associatesOnly'];

        this.groupsBaseData = [];
        this.qualificationBaseData = [];
        this.privUsersBaseData = [];
        this.readingUsersBaseData = [];

        for (let element of documentTarget['qualifications']) {
            let qualificationToAdd: Qualification = {
                id: null,
                title: element,
                actions: false
            };
            this.qualificationBaseData.push(qualificationToAdd);
        }

        for (let element of documentTarget['usersGroups']) {
            let groupToAdd: Group = {
                name: element,
                actions: false
            };
            this.groupsBaseData.push(groupToAdd);
        }

        for (let element of documentTarget['personalUsers']) {
            let nominative = element.split(' ');
            let name = nominative[nominative.length - 1];
            nominative.splice(-1, 1);
            let surname = nominative.join(" ");
            let userToAdd: User = {
                surname: surname,
                name: name,
                actions: false
            };
            this.privUsersBaseData.push(userToAdd);
        }

    }

    public ellipsify(str: string) {
        if (str && str.length > 40) {
            return (str.substring(0, 40) + "...");
        }
        else {
            return str;
        }
    }

    private setupDocumentShares(documentShares: any) {
        for (let element of documentShares) {
            let readDate: Date = new Date(element['readingDate']);

            let readingConfirm: ReadingConfirm = {
                user: {
                    qualificationName: element['user']['qualification'],
                    companyNames: element['user']['companyNames'],
                    name: element['user']['name'],
                    surname: element['user']['surname'],
                    fiscalCode: element['user']['fiscalCode'],
                    groupName: element['group']?.name || undefined
                },
                read: element['readden'],
                readingDate: 'Letto il ' + String(readDate.getDate()).padStart(2, '0') + '/' + String((readDate.getMonth() + 1)).padStart(2, '0') + '/' + readDate.getFullYear() + ' \nalle ' +
                    String(readDate.getHours()).padStart(2, '0') + ':' + String(readDate.getMinutes()).padStart(2, '0') + ':' + String(readDate.getSeconds()).padStart(2, '0')
            };
            this.readingUsersBaseData.push(readingConfirm);
        }
    }

    private setType(docType: number): string {
        switch (docType) {
            case ALTRO:
                return 'Altro';
            case CDA_ASSEMBLEA:
                return 'CDA e Assemblea soci';
            case COMUNICAZIONI:
                return 'Comunicazioni';
            case CONTABILITA:
                return 'Contabilita\'';
            case PRIVACY:
                return 'Privacy';
            case SICUREZZA:
                return 'Sicurezza';
            default:
                break;
        }
    }

    downloadDocument() {
        this._fileService.downloadFile(this.id, 'document').then(response => {
            this._fileService.saveData(response, this.documentData.title);
        });
    }

    exportDocumentInfo() {
        let docInfo = {
            title: this.title,
            description: this.description,
            shareDate: this.shareDate,
            type: this.type
        }

        this.exportPdf(docInfo);
    }

    exportPdf(docInfo) {
        const doc = new jsPDF();
        doc.text("Dati documento", 15, 15);
        autoTable(doc, {
            startY: 20,
            head: [['Titolo', 'Descrizione' , 'Data di condivisione', 'Tipo']],
            body: [[docInfo.title, docInfo.description, docInfo.shareDate, docInfo.type]],
            styles: { halign : 'center'}, headStyles :{fillColor : "#43bcc9"}
        });

        let usersTableBody = [];
        this.readingUsersBaseData.forEach(element => {
            let readStatus = element.readingDate?.split(" ")[2] + '\n' + '(' + element.readingDate?.split(" ")[4] + ')';
            let userData = [this.ellipsify(element.user.companyNames?.toString()) || '-',
                            element.user.groupName || '-' ,
                            this.ellipsify(element.user.qualificationName) || '-',
                            element.user.surname, element.user.name, element.user.fiscalCode, element.read? 'Si\n' + readStatus : 'No'];
            usersTableBody.push(userData);
        })
        doc.text("Dettagli presa visione", 15, (doc as any).autoTable.previous.finalY + 10);
        autoTable(doc, {
            startY: (doc as any).autoTable.previous.finalY + 15,
            head: [['Società', 'Gruppo', 'Qualifica', 'Cognome', 'Nome', 'Codice Fiscale', 'Letto']],
            body: usersTableBody,
            styles: { halign : 'center'}, headStyles :{fillColor : "#43bcc9"}
        });

        doc.save("Riepilogo_" + docInfo.title.substr(0, 10) + "_" + docInfo.shareDate.replace("/", "-") + '.pdf');
    }
}

