<div fxFlex fxFill fxLayout="column">

    <!-- higher toolbar for username -->

    <mat-toolbar class="p-0 mat-elevation-z1">
        <div fxFlex fxFill fxLayout="row">
            <!-- top-left in the toolbar -->
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center"></div>
            <!-- top-right in the toolbar -->
            <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
                <button mat-button [matMenuTriggerFor]="logout" class="user-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <span class="username mr-12" fxHide fxShow.gt-sm>{{ userSurname }} {{userName}} - {{ userRole }}</span>
                        <div class="spacer"></div>
                        <img class="avatar mr-0 mr-sm-16" src="{{userIconPath}}" />
                    </div>
                </button>
                <mat-menu #logout="matMenu" xPosition="before" class="logout-item">
                    <button mat-menu-item class="logout-button" (click)="logoutAction()" [routerLink]="'pages/auth/login'">
                        Disconnetti
                    </button>
                </mat-menu>
            </div>
        </div>
    </mat-toolbar>

    <!-- lower toolbar for navigation buttons -->

    <mat-toolbar class="p-0 mat-elevation-z1 t-bar">
        <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
            <!-- top-left in the toolbar -->
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center"></div>

            <!-- top-right in the toolbar -->
            <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
                <div class="toolbar-separator"></div>

                <button mat-button class="toolbar-button" [routerLink]="'apps/home'">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="toolbar-icon">home</mat-icon>
                        <span class="username mr-12 menu-button-text" fxHide fxShow.gt-sm>
                            Home</span>
                        </div>
                </button>

                <div class="toolbar-separator" *ngIf="userRole === 'RBAC_ADMIN' || userRole === 'AMMINISTRATORE'"></div>

                <button mat-button [matMenuTriggerFor]="companyManagement" class="toolbar-button"
                        *ngIf="userRole === 'RBAC_ADMIN'|| userRole === 'AMMINISTRATORE'">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="toolbar-icon">account_balance</mat-icon>
                        <span class="username mr-12 menu-button-text" fxHide fxShow.gt-sm>Società</span>
                        <mat-icon class="s-16 menu-arrow" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>
                <mat-menu class="menu-custom-item" #companyManagement="matMenu" xPosition="before">
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/company-management/new-company'">
                        Nuovo
                    </button>
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/company-management/list-companies'">
                        Lista
                    </button>
                </mat-menu>

                <div class="toolbar-separator" *ngIf="userRole !== 'RESPONSABILE'"></div>

                <button mat-button [matMenuTriggerFor]="usersManagement"
                        *ngIf="userRole !== 'RESPONSABILE'" class="toolbar-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="toolbar-icon">group</mat-icon>
                        <span class="username mr-12 menu-button-text" fxHide fxShow.gt-sm>Utenti</span>
                        <mat-icon class="s-16 menu-arrow" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>
                <mat-menu class="menu-custom-item" #usersManagement="matMenu" xPosition="before">
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/users-management/new-user'">
                        Nuovo
                    </button>
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/users-management/list-users'">
                        Lista
                    </button>
                </mat-menu>
                <div class="toolbar-separator" *ngIf="userRole !== 'RESPONSABILE'"></div>
                <button mat-button [matMenuTriggerFor]="gruppiTrigger"
                        *ngIf="userRole !== 'RESPONSABILE'" class="toolbar-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="toolbar-icon">work_outline</mat-icon>
                        <span class="username mr-12 menu-button-text" fxHide fxShow.gt-sm>Gruppi</span>
                        <mat-icon class="s-16 menu-arrow" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>
                <mat-menu class="menu-custom-item" #gruppiTrigger="matMenu" xPosition="before">
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/groups-manager/new'">
                        Nuovo
                    </button>
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/groups-manager/list'">
                        Lista
                    </button>
                </mat-menu>
                <div class="toolbar-separator"></div>

                <button mat-button [matMenuTriggerFor]="documentsManagement" class="toolbar-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="toolbar-icon">insert_drive_file</mat-icon>
                        <span class="username mr-12 menu-button-text" fxHide fxShow.gt-sm>Documenti</span>
                        <mat-icon class="s-16 menu-arrow" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>
                <mat-menu class="menu-custom-item" #documentsManagement="matMenu" xPosition="before">
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/documents-management/share'">
                        Condividi
                    </button>
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/documents-management/list'">
                        Lista
                    </button>
                    <button class="menu-button" mat-menu-item *ngIf="userRole === 'RBAC_ADMIN' || userRole === 'AMMINISTRATORE'"
                            [routerLink]="'apps/documents-management/paychecks'">
                        Carica cedolini
                    </button>
                </mat-menu>

                <div class="toolbar-separator"></div>

                <button mat-button [matMenuTriggerFor]="testsManagement" class="toolbar-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="toolbar-icon">assignment</mat-icon>
                        <span class="username mr-12 menu-button-text" fxHide fxShow.gt-sm>Test</span>
                        <mat-icon class="s-16 menu-arrow" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>
                <mat-menu class="menu-custom-item" #testsManagement="matMenu" xPosition="before">
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/tests-management/new'">
                        Somministra
                    </button>
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/tests-management/list'">
                        Lista
                    </button>
                </mat-menu>
                <div class="toolbar-separator"></div>

               <button mat-button [matMenuTriggerFor]="communicationsManager" class="toolbar-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon class="toolbar-icon">notifications_active</mat-icon>
                        <span class="username mr-12 menu-button-text" fxHide fxShow.gt-sm>Comunicazioni</span>
                        <mat-icon class="s-16 menu-arrow" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>
                <mat-menu class="menu-custom-item" #communicationsManager="matMenu" xPosition="before">
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/comms-management/new-comunication'">
                        Nuovo
                    </button>
                    <button class="menu-button" mat-menu-item [routerLink]="'apps/comms-management/list-communications'">
                        Lista
                    </button>
                </mat-menu>
                <div class="toolbar-separator"></div>

            </div>
        </div>
    </mat-toolbar>
</div>
