import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {PasswordRecoverService} from '../../../services/password-recover.service';
import {ActivatedRoute, Router} from '@angular/router';
import {METHOD_NOT_ALLOWED, STATUS_UNAUTHORIZED} from '../../../apps/utils/commons.component';
import {WarningDialogAdapter} from '../../../apps/utils/warning-dialog/warning-dialog-adapter';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {WarningDialogComponent} from '../../../apps/utils/warning-dialog/warning-dialog.component';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    passwordChange: boolean;
    private static _fromMobile: boolean;
    resetPasswordForm: FormGroup;
    private _pToken: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _route: ActivatedRoute,
        private _recoverService: PasswordRecoverService,
        private _dialog: MatDialog
    ) {
        this.passwordChange = false;
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }


    ngOnInit(): void {
        this._pToken = this._route.snapshot.queryParamMap.get("pToken");
        ResetPasswordComponent._fromMobile = Boolean(this._route.snapshot.queryParamMap.get("mobile"));
        this.passwordChange = JSON.parse(this._route.snapshot.queryParamMap.get("changePassword"));

        this.resetPasswordForm = this._formBuilder.group({
            password: ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    public resetPassword(): void {
        if (!this.resetPasswordForm.get("password").hasError("required") &&
            !this.resetPasswordForm.get("passwordConfirm").hasError("required") &&
            this.resetPasswordForm.get("passwordConfirm").value === this.resetPasswordForm.get("password").value) {
            this._recoverService.resetPasswordByPToken(this._pToken, this.resetPasswordForm.get("password").value).then(result => {
                if (result['status'] === true) {
                    if (ResetPasswordComponent._fromMobile) {
                        if (this.passwordChange) {
                            WarningDialogAdapter.showWarning(this._dialog, "Modifica password", "Modifica password effettuata.\nTornare nell'app e accedere con le nuove credenziali.");
                        } else {
                            WarningDialogAdapter.showWarning(this._dialog, "Reset password", "Reset password effettuato.\nTornare nell'app e accedere con le nuove credenziali.");
                        }
                    } else {
                        if (this.passwordChange) {
                            WarningDialogAdapter.showWarning(this._dialog, "Modifica password", "Modifica password effettuata.");
                        } else {
                            WarningDialogAdapter.showWarning(this._dialog, "Reset password", "Reset password effettuato.");
                        }
                        this._router.navigate(["/"]);
                    }
                } else if (result['status'] === METHOD_NOT_ALLOWED || result['status'] === STATUS_UNAUTHORIZED) {
                    if (this.passwordChange) {
                        WarningDialogAdapter.showWarning(this._dialog, "Errore nella modifica della password", result['error']['result']);
                    } else {
                        WarningDialogAdapter.showWarning(this._dialog, "Errore nel reset della password", result['error']['result']);
                    }
                } else {
                    if (this.passwordChange) {
                        WarningDialogAdapter.showWarning(this._dialog, "Errore nella modifica della password", result['error']['details']);
                    } else {
                        WarningDialogAdapter.showWarning(this._dialog, "Errore nel reset della password", result['error']['details']);
                    }                }
            });
        }
    }

    static get fromMobile(): boolean {
        return this._fromMobile;
    }

    static set fromMobile(value: boolean) {
        this._fromMobile = value;
    }

    resetForm() {
        this.resetPasswordForm.reset();
        this.resetPasswordForm.get("password").setErrors(null);
        this.resetPasswordForm.get("passwordConfirm").setErrors(null);
    }
}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return {passwordsNotMatching: true};
};
