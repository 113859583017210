import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastManager} from '../../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CommunicationsService} from '../../../../services/communications.service';
import {TooltipPosition} from '@angular/material/tooltip';
import {ReadingConfirm} from '../../../../entity/reading-confirm.component';
import {MatTableDataSource} from '@angular/material/table';
import {CONFLICT, METHOD_NOT_ALLOWED, STATUS_UNAUTHORIZED} from '../../../utils/commons.component';
import {CommunicationType, EMPTY_COMMUNICATION_TYPE} from "../../../../entity/communication-type";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
    selector: 'app-show-communication-dialog',
    templateUrl: './show-communication-dialog.component.html',
    styleUrls: ['./show-communication-dialog.component.scss']
})
export class ShowCommunicationDialogComponent extends ToastManager implements OnInit {
    toolTipPosition: TooltipPosition;
    shareDate: string;

    readingUsersBaseData: ReadingConfirm[];
    readingConfirmationColumns: string[];
    readingUsers: MatTableDataSource<ReadingConfirm>;

    title: string;
    message: string;
    priority: string;
    type: number;
    types: CommunicationType[];

    constructor(@Inject(MAT_DIALOG_DATA) public communicationData: any,
                private dialogRef: MatDialogRef<ShowCommunicationDialogComponent>,
                private _sBar: MatSnackBar,
                public communicationService: CommunicationsService) {
        super(_sBar);
        this.toolTipPosition = 'above';
        this.shareDate = "";


        this.communicationService.getCommunicationsTypes().then(result => {
            if (result['status'] === true) {
                this.types = result['communicationTypeList'];
            } else {
                this.types = [EMPTY_COMMUNICATION_TYPE];
            }
        });

        this.readingUsersBaseData = [];
        this.readingUsers = new MatTableDataSource<ReadingConfirm>();
        this.readingConfirmationColumns = [
            'company',
            'group',
            'qualification',
            'surname',
            'name',
            'fiscalCode',
            'read'
        ];
        this.getCommunicationDetails(communicationData.communication.id);
    }

    ngOnInit(): void {
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public ellipsify(str: string) {
        if (str && str.length > 40) {
            return (str.substring(0, 40) + "...");
        }
        else {
            return str;
        }
    }

    private getCommunicationDetails(id: number) {
        this.communicationService.getCommunicationById(id).then(result => {
            let message: string = '';
            if (result['status'] === true) {
                this.setupCommunicationInfo(result['communicationInfo']);
                this.setupCommunicationShares(result['communicationSharesInfo']);

            } else if (result['status'] === METHOD_NOT_ALLOWED || result['status'] === STATUS_UNAUTHORIZED || result['status'] === CONFLICT) {
                message = result['error']['result'];
            } else {
                message = result['error']['result'];
            }
            this.showToast(message);
        });
    }

    private setupCommunicationShares(communicationShares: any) {
        for (let element of communicationShares) {
            let readDate: Date = new Date(element['readingDate']);
            let shareDate: Date = new Date(element['shareDate']);
            this.shareDate = String(shareDate.getDate()).padStart(2, '0') + "/" + String(shareDate.getMonth() + 1).padStart(2, '0') + "/" + shareDate.getFullYear()

            let readingConfirm: ReadingConfirm = {
                user: {
                    qualificationName: element['user']['qualification'],
                    companyNames: element['user']['companyNames'],
                    name: element['user']['name'],
                    surname: element['user']['surname'],
                    fiscalCode: element['user']['fiscalCode'],
                    groupName: element['group']?.name || undefined
                },
                read: element['read'],
                readingDate: 'Letto il ' + String(readDate.getDate()).padStart(2, '0') + '/' + String((readDate.getMonth() + 1)).padStart(2, '0') + '/' + readDate.getFullYear() + ' \nalle ' +
                    String(readDate.getHours()).padStart(2, '0') + ':' + String(readDate.getMinutes()).padStart(2, '0') + ':' + String(readDate.getSeconds()).padStart(2, '0')
            };
            this.readingUsersBaseData.push(readingConfirm);
        }
        this.readingUsers.data = this.readingUsersBaseData;
    }

    private setupCommunicationInfo(communicationInfo: any) {
        this.title = communicationInfo['title'];
        this.message = communicationInfo['message'];
        this.priority = communicationInfo['priority'].toLowerCase();
        this.type = communicationInfo['type']? communicationInfo['type']['id'] : 'Nessuna tipologia';
    }

    exportDocumentInfo() {
        console.log(this.shareDate)
        let docInfo = {
            title: this.title,
            description: this.message,
            shareDate: this.shareDate,
            type: this.types.find(type => type.id === this.type)?.name || 'Nessuna tipologia',
        }

        this.exportPdf(docInfo);
    }

    exportPdf(docInfo) {
        const doc = new jsPDF();
        doc.text("Dati comunicazione", 15, 15);
        autoTable(doc, {
            startY: 20,
            head: [['Titolo', 'Messaggio' , 'Data invio', 'Tipo']],
            body: [[docInfo.title, docInfo.description, docInfo.shareDate, docInfo.type]],
            styles: { halign : 'center'}, headStyles :{fillColor : "#43bcc9"}
        });

        let usersTableBody = [];
        this.readingUsersBaseData.forEach(element => {
            let readStatus = element.readingDate?.split(" ")[2] + '\n' + '(' + element.readingDate?.split(" ")[4] + ')';
            let userData = [this.ellipsify(element.user.companyNames?.toString()) || '-',
                element.user.groupName || '-' ,
                this.ellipsify(element.user.qualificationName) || '-',
                element.user.surname, element.user.name, element.user.fiscalCode, element.read? 'Si\n' + readStatus : 'No'];
            usersTableBody.push(userData);
        })
        doc.text("Dettagli presa visione", 15, (doc as any).autoTable.previous.finalY + 10);
        autoTable(doc, {
            startY: (doc as any).autoTable.previous.finalY + 15,
            head: [['Società', 'Gruppo', 'Qualifica', 'Cognome', 'Nome', 'Codice Fiscale', 'Letto']],
            body: usersTableBody,
            styles: { halign : 'center'}, headStyles :{fillColor : "#43bcc9"}
        });

        doc.save("Riepilogo_" + docInfo.title.substr(0, 10) + "_" + docInfo.shareDate.replace("/", "-") + '.pdf');
    }
}
