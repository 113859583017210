
<mat-dialog-content id="confirm-dialog">
    <h1 mat-dialog-title class="containers">{{dialogTitle}}</h1>
    <div mat-dialog-content class="containers">{{dialogContent}}</div>
</mat-dialog-content>

<mat-dialog-actions class="buttons-container">
    <button mat-raised-button fxFill fxFlex color="accent" [mat-dialog-close]="{type: operationType, reply: true}">Sì</button>
    <button mat-raised-button fxFill fxFlex color="lightgray" [mat-dialog-close]="{type: operationType, reply: false}">No</button>
</mat-dialog-actions>
