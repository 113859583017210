<div id="show-document" class="page-layout blank">
    <div class="spin-loading-container" *ngIf="documentsService.isLoadingSomething">
        <div class="loading-wrapper" fxLayout="column">
            <mat-spinner class="loading-spinner"></mat-spinner>
            <span class="loading-text">{{documentsService.whatIsLoading}}</span>
        </div>
    </div>
    <mat-dialog-content fxFlexFill fxLayout="column" id="show-document-form" *ngIf="!documentsService.isLoadingSomething">

        <div fxLayout="row" class="title-container dialog-title title-div">
            <mat-label fxFlex class="title">
                Visualizza documento
            </mat-label>
        </div>
        <div fxLayout="row" class="form-container">
            <div class="card top-card">
                <div class="card-preview">
                    <div class="my-card">
                        <div fxFill fxFlex fxLayout="column">
                            <div fxLayout="row" class="card-header">
                                <mat-label class="card-title">
                                    Dati documento
                                </mat-label>
                            </div>
                            <div fxLayout="row" class="base-form-container">
                                <div fxFill fxFlex fxLayout="column" class="left-form">
                                    <div fxLayout="row">
                                        <mat-form-field class="fields">
                                            <mat-label>Titolo</mat-label>
                                            <input matInput [disabled]="true" [ngModel]="title">
                                        </mat-form-field>
                                        <mat-form-field class="doc-type-field">
                                            <mat-label>Tipologia documento</mat-label>
                                            <input matInput [disabled]="true" [ngModel]="type">
                                        </mat-form-field>
                                        <a mat-flat-button class="doc-type-field download-button" fxLayout="row"
                                           (click)="downloadDocument()">
                                            Documento
                                            <mat-icon class="download-icon">get_app</mat-icon>
                                        </a>
                                    </div>
                                    <mat-form-field class="fields text-area-field">
                                        <mat-label>Descrizione</mat-label>
                                        <textarea matInput [disabled]="true" [ngModel]="description"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div fxLayout="column" class="list-selector-container">
            <div fxFill fxFlex fxLayout="row">
                <div fxFlex fxFill class="card top-card">
                    <div class="card-preview">
                        <div class="my-card">
                            <div fxFill fxFlex fxLayout="column">
                                <div fxLayout="row" class="card-header">
                                    <mat-label class="card-title">
                                        Dettaglio presa visione
                                    </mat-label>
                                </div>

                                <div fxFill fxFlex fxLayout="column" class="table-container">
                                    <table mat-table [dataSource]="readingUsers" class="mat-elevation-z8 table-style">
                                        <ng-container matColumnDef="company">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Società
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ ellipsify(element.user.companyNames?.toString()) || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="group">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Gruppo
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.user.groupName || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="qualification">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Qualifica
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.user.qualificationName || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="surname">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Cognome
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.user.surname }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Nome
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.user.name }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="fiscalCode">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Codice fiscale
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.user.fiscalCode }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="read">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Letto
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-icon class="green-icon" *ngIf="element.read"
                                                          matTooltip="{{element.readingDate}}"
                                                          [matTooltipPosition]="toolTipPosition" [matTooltipShowDelay]="500"
                                                          matTooltipClass="custom-tooltip">
                                                    done
                                                </mat-icon>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row class="table-header" *matHeaderRowDef="
                                                    readingConfirmationColNames;
                                                    sticky: true
                                                "></tr>
                                        <tr mat-row *matRowDef="
                                                    let row;
                                                    columns: readingConfirmationColNames
                                                "></tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </mat-dialog-content>
    
    <mat-dialog-actions fxLayout="row" class="buttons-container" *ngIf="!documentsService.isLoadingSomething">
        <button mat-raised-button color="accent" class="confirm-button" aria-label="EXIT" (click)="exportDocumentInfo()">
            Esporta
        </button>
        <button mat-raised-button class="cancel-button exit-button" aria-label="EXIT" (click)="closeDialog()">
            Esci
        </button>
    </mat-dialog-actions>
</div>
