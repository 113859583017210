import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenManagerService} from './token-manager.service';
import {User} from '../entity/user.component';
import {LoadingInfo} from './loading-info/loading-info';
import {Endpoints} from '../apps/utils/Endpoints';
import {Qualification} from '../entity/qualification.component';
import {EMPTY_COMPANIES_PLACE_HOLDER} from "../entity/company.component";

@Injectable({
    providedIn: 'root'
})
export class UserService extends LoadingInfo {

    public loggedUser: {
        name: string,
        surname: string,
        role: string
    };

    constructor(private _httpClient: HttpClient, private tokenManagerService: TokenManagerService) {
        super();
    }

    public async getUsers(page: number, sortType: string, sortBy: string, pageSize: number, filter?: any) {
        this.whatIsLoading = 'Caricamento utenti';
        this.isLoadingSomething = true;
        let promise;
        let body;

        if(filter !== null && filter !== undefined) {
            body = {
                authToken: this.tokenManagerService.authToken,
                page: page,
                sortType: sortType,
                sortBy: sortBy,
                pageSize: pageSize,
                filterEnabled: true,
                nameFilter: filter.name,
                surnameFilter: filter.surname,
                fiscalCodeFilter: filter.fiscalCode,
                companyIdFilter: filter.companyId
            };
        }else{
            body = {
                authToken: this.tokenManagerService.authToken,
                page: page,
                sortType: sortType,
                sortBy: sortBy,
                pageSize: pageSize,
                filterEnabled: false
            };
        }
        await this._httpClient.post(
            Endpoints.GET_USERS_BY_PAGE, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });
        return promise;
    };

    public async disableUser(fCode: string) {

        this.whatIsLoading = 'Eliminazione utente';
        this.isLoadingSomething = true;
        let promise;
        const body = {
            authToken: this.tokenManagerService.authToken,
            fiscalCode: fCode
        };

        await this._httpClient.post(
            Endpoints.DELETE_USER, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async insertNewUser(user: User) {

        this.whatIsLoading = 'Inserimento nuovo utente';
        this.isLoadingSomething = true;
        let promise;

        if (user.companies[0] === EMPTY_COMPANIES_PLACE_HOLDER) {
            user.companies = [];
        }

        const body = {
            authToken: this.tokenManagerService.authToken,
            user: user
        };

        await this._httpClient.post(
            Endpoints.INSERT_NEW_USER, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;

    }

    public async getUserInfo(fCode: string) {

        this.whatIsLoading = 'Caricamento dettagli utente';
        this.isLoadingSomething = true;
        let promise;
        const body = {
            authToken: this.tokenManagerService.authToken,
            fiscalCode: fCode
        };

        await this._httpClient.post(
            Endpoints.GET_USER_INFO, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async existsUser(fCode: string, email: string) {

        this.whatIsLoading = 'Controllo se l\'utente è già registrato';
        this.isLoadingSomething = true;
        let promise;
        const body = {
            emailAddress: email,
            fiscalCode: fCode,
            authToken: this.tokenManagerService.authToken
        };

        await this._httpClient.post(
            Endpoints.CHECK_USER_EXISTANCE, body, {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/json')
            }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async updateUser(user: User, ref: string) {

        this.whatIsLoading = 'Aggiornamento utente';
        this.isLoadingSomething = true;
        let promise;

        user.authToken = this.tokenManagerService.authToken;
        user.ref = ref;

        if (user.companies[0] === EMPTY_COMPANIES_PLACE_HOLDER) {
            user.companies = [];
        }

        let body = JSON.stringify(user);

        await this._httpClient.post(
            Endpoints.UPDATE_USER, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async getUserQualifications() {

        let qualificationsArray: Qualification[];

        const body = {
            authToken: this.tokenManagerService.authToken
        };

        await this._httpClient.post(
            Endpoints.GET_USERS_QUALIFICATIONS, body, {
                headers: new HttpHeaders()
                    .set("Content-Type", "application/json")
            }).toPromise().then((response) => {
                qualificationsArray = response['qualifications'];
        })
        qualificationsArray.sort((a, b) => {
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        });
        return qualificationsArray;
    }

    public async getTargetUsers() {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento dati';
        let promise;
        const body = {
            authToken: this.tokenManagerService.authToken
        };

        await this._httpClient.post(
            Endpoints.GET_TARGET_USERS, body, {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/json')
            }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

}
