<div id="edit-group" class="page-layout blank">
    <div class="spin-loading-container" *ngIf="groupService.isLoadingSomething">
        <div class="loading-wrapper" fxLayout="column">
            <mat-spinner class="loading-spinner"></mat-spinner>
            <span class="loading-text">{{groupService.whatIsLoading}}</span>
        </div>
    </div>
    <div fxFlexFill fxLayout="column" id="edit-group-form" *ngIf="!groupService.isLoadingSomething">
        <div fxLayout="row" class="title-container dialog-title title-div">
            <mat-label fxFlex class="title">
                Modifica Gruppo: {{groupData.group.name}}
            </mat-label>
            <mat-label fxFlex class="warn-message">
                {{errorMessage}}
            </mat-label>
        </div>
        <div class="spacer"></div>
        <div fxLayout="row" class="base-form-container">
            <div class="card">
                <div class="card-preview">
                    <div class="my-card">
                        <div fxFill fxFlex fxLayout="column">
                            <div fxLayout="row" class="card-header">
                                <mat-label fxFlex class="card-title">
                                    Dati Gruppo
                                </mat-label>
                            </div>
                            <div class="spacer"></div>
                            <div fxLayout="row" class="base-form-container" [formGroup]="editGroupForm">
                                <mat-form-field class="left-field" appearance="fill">
                                    <mat-label>Nome</mat-label>
                                    <input matInput formControlName="groupName" required/>
                                </mat-form-field>
                                <mat-form-field class="right-field" appearance="fill">
                                    <mat-label>Società</mat-label>
                                    <mat-select formControlName="company" required (selectionChange)="getAvailableModerators($event.value)">
                                        <mat-option *ngFor="let company of companies" [value]="company.id">
                                            {{company.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="right-field" appearance="fill">
                                    <mat-label>Responsabile</mat-label>
                                    <mat-select formControlName="moderator" (selectionChange)="updateModerator($event)" required>
                                        <mat-option *ngFor="let user of users" [disabled]="user.fiscalCode === null"
                                                    [value]="user.emailAddress">{{user.surname}}
                                            {{user.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="right-field" appearance="fill">
                                    <mat-label>Tipologia</mat-label>
                                    <mat-select formControlName="type" required>
                                        <mat-option [value]="0">Centro di costo</mat-option>
                                        <mat-option [value]="1">Gruppo</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                       </div> 
                    </div>
                </div>
            </div>
        </div>

    </div>
        <mat-dialog-actions fxLayout="row" class="buttons-container" *ngIf="!groupService.isLoadingSomething">
            <button mat-raised-button class="cancel-button" aria-label="CANCEL" (click)="closeDialog(false)">
                Annulla
            </button>
            <div class="spacer"></div>
            <button mat-raised-button color="accent" class="confirm-button" aria-label="SAVE" (click)="closeDialog(true)">
                Salva
            </button>
    </mat-dialog-actions>
</div>
