import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    Inject,
} from "@angular/core";
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Group } from "app/main/entity/group.component";
import {
    FormGroup,
    FormControl,
    Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import {
    METHOD_NOT_ALLOWED,
    STATUS_UNAUTHORIZED, CONFLICT, TEXT_AND_SYMBOL_REGEX
} from 'app/main/apps/utils/commons.component';
import {GroupService} from '../../../../services/group.service';
import {ToastManager} from '../../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Company, EMPTY_COMPANIES_PLACE_HOLDER} from '../../../../entity/company.component';
import {CompanyService} from '../../../../services/company.service';
import {EMPTY_RESPONSIBLE_PLACE_HOLDER, User} from '../../../../entity/user.component';

@Component({
    selector: "group",
    templateUrl: "./edit-group-dialog.component.html",
    styleUrls: ["./edit-group-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class EditGroupDialogComponent extends ToastManager implements OnInit, OnDestroy {
    users: User[];
    companies: Company[];
    refGroup: string;

    moderatorUser: User;
    editGroupForm: FormGroup;
    errorMessage: string;
    validatorsSubscription: Subscription;

    constructor(
        private dialogRef: MatDialogRef<EditGroupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public groupData: any,
        public groupService: GroupService,
        private _dialog: MatDialog,
        private companyService: CompanyService,
        private _sBar: MatSnackBar
    ) {
        super(_sBar);
        this.refGroup = groupData.group.name;
        this.companies = [];
        this.moderatorUser = {
            surname: groupData.group.responsibleFullName.split(" ")[0],
            name: groupData.group.responsibleFullName.split(" ")[1],
            emailAddress: groupData.group.responsibleEmailAddress
        }

        this.companyService.getCompanyNames().then((result) => {
            this.companyService.isLoadingSomething = false;
            this.companies = [];
            result['companies'].forEach(company => {
                let newCompany: Company = {
                    name: company.name,
                    id: company.companyId,
                    iva: null,
                    moderator: {
                        name: '',
                        surname: '',
                        qualification: {id: null, title: '', actions: false}
                    },
                    headquarters: {
                        region: {
                            id: null,
                            name: ''
                        },
                        province: {
                            id: null,
                            code: '',
                            name: ''
                        },
                        city: {
                            id: null,
                            name: ''
                        },
                        postalCode: null,
                        address: ''
                    },
                }

                if (newCompany.name === groupData.group.companyName) {
                    groupData.group.companyId = newCompany.id;
                    this.editGroupForm.get('company').setValue(groupData.group.companyId);
                    this.getAvailableModerators(groupData.group.companyId);
                }

                this.companies.push(newCompany);
            })
        }).catch(() => {
            this.companyService.isLoadingSomething = false;
            if (this.companies.length === 0) {
                this.companies = [EMPTY_COMPANIES_PLACE_HOLDER];
            }
        })
        this.editGroupForm = new FormGroup({
            groupName: new FormControl(groupData.group.name, [Validators.required, Validators.pattern(TEXT_AND_SYMBOL_REGEX)]),
            moderator: new FormControl(null, [
                Validators.required,
            ]),
            company: new FormControl(groupData.group.companyId, Validators.required),
            type: new FormControl(null, [Validators.required])
        });

        this.editGroupForm.get('type').setValue(groupData.group.type);
        this.errorMessage = "";
    }

    ngOnInit(): void {
        this.validatorsSubscription = this.editGroupForm.statusChanges.subscribe(
            (val) => {
                if (val == "VALID") {
                    this.errorMessage = "";
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.validatorsSubscription.unsubscribe();
    }

    public closeDialog(savePreferences: boolean): void {
        if (savePreferences) {
            this.editGroupForm.markAllAsTouched();
            if (
                this.editGroupForm.get("groupName").hasError("required") ||
                this.editGroupForm.get("moderator").hasError("required") ||
                this.editGroupForm.get("company").hasError("required") ||
                this.editGroupForm.get('type').hasError('required')
            ) {
                this.errorMessage =
                    "Attenzione! Campi obbligatori non compilati.";
                return;
            }else if(this.editGroupForm.get("groupName").hasError("pattern")) {
                this.errorMessage = "Attenzione! Campo/i non popolati correttamente.";
                return;
            }
            let group: Group = {
                name: (this.editGroupForm.get("groupName").value.replace(/^\s+|\s+$|\s+(?=\s)/g, '') !== this.groupData.group.name)?
                    this.editGroupForm.get("groupName").value.replace(/^\s+|\s+$|\s+(?=\s)/g, '') : undefined,
                responsibleEmailAddress: this.editGroupForm.get('moderator').value,
                id: this.groupData.group.id,
                companyId: (this.editGroupForm.get("company").value !== this.groupData.group.companyId) ||
                    (this.editGroupForm.get("groupName").value.replace(/^\s+|\s+$|\s+(?=\s)/g, '') !== this.groupData.group.name)?
                    this.editGroupForm.get("company").value : undefined,
                type: this.editGroupForm.get('type').value,
                actions: true
            };

            this.groupService.updateGroup(group).then(result => {
                let message: string = "";
                if(result['status'] === true) {
                    message = "Gruppo modificato correttamente";
                    this.dialogRef.close(group);
                } else if (result['status'] === CONFLICT) {
                    this.errorMessage = result['error']['result']
                } else if(result['status'] === METHOD_NOT_ALLOWED || result['status'] === STATUS_UNAUTHORIZED) {
                    message = result['error']['result'];
                } else{
                    message = result['error']['result'];
                }
                this.showToast(message);
            });

            return;
        }
        this.dialogRef.close(null);
    }

    public getAvailableModerators(companyId: number): void {
        this.editGroupForm.get("moderator").setValue(null);

        this.groupService.getAllGroupModerators(companyId).then(result => {
            let message: string = "";
            if(result['status'] === true) {
                if (result['responsibles'].length === 0)
                    this.users = [EMPTY_RESPONSIBLE_PLACE_HOLDER];
                else {
                    this.users = result['responsibles'];
                    this.users.sort((a, b) => (a.surname > b.surname) ? 1 : ((b.surname > a.surname) ? -1 : 0));
                    this.users.forEach(user => {
                        if (user.emailAddress === this.moderatorUser.emailAddress) {
                            this.editGroupForm.get("moderator").setValue(this.moderatorUser.emailAddress);
                        }
                    });
                }
            } else if (result['status'] === CONFLICT) {
                this.errorMessage = result['error']['result']
            } else if(result['status'] === METHOD_NOT_ALLOWED || result['status'] === STATUS_UNAUTHORIZED) {
                message = result['error']['result'];
            } else{
                message = result['error']['result'];
            }
            this.showToast(message);
        });
    }

    public updateModerator(event: any) {
        this.moderatorUser = this.users.filter(moderator => {
            if(moderator.fiscalCode === event.value) {
                return moderator;
            }
        })[0];
    }

}
