import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenManagerService} from './token-manager.service';
import {LoadingInfo} from './loading-info/loading-info';
import {Endpoints} from '../apps/utils/Endpoints';

@Injectable({
  providedIn: 'root'
})
export class FileService extends LoadingInfo{

  constructor(private _httpClient: HttpClient, private _tokenManagerService: TokenManagerService) {
      super();
  }

    public async uploadPDFFile(file: File) {
      this.isLoadingSomething = true;
      this.whatIsLoading = "Caricamento del file";
        let promise;

        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('authToken', this._tokenManagerService.authToken);

        await this._httpClient.post(Endpoints.UPLOAD_FILE, formData, {
            reportProgress: true,
            responseType: 'json',
            headers: new HttpHeaders({'Accept': 'application/json'})
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public saveData(file, fileName) {
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('display', 'none');
        let url = window.URL.createObjectURL(file);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };

    public async oldSaveFile(fileName: string) {
      this.isLoadingSomething = true;
      this.whatIsLoading = "Download del file";
        let promise;

        await this._httpClient.get(this.getDownloadFileUrl(fileName), {
            reportProgress: true,
            responseType: 'blob',
            headers: new HttpHeaders({'Accept': 'application/octet-stream'})
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });
        return promise;
    }

    public async downloadFile(documentId: number, type: string) {
        this.isLoadingSomething = true;
        this.whatIsLoading = "Download del file";
            let promise;

            await this._httpClient.post(Endpoints.DOWNLOAD_FILE + '/' + documentId,
                { type, authToken: this._tokenManagerService.authToken, }, {
                reportProgress: true,
                responseType: 'blob'
            })
                .toPromise().then(result => {
                this.isLoadingSomething = false;
                promise = result;
            }).catch(error => {
                this.isLoadingSomething = false;
                promise = error;
            });
            return promise;
    }

    public getDownloadFileUrl(fileName: string) {
        const url = Endpoints.OLD_DOWNLOAD_FILE + "/" + this._tokenManagerService.authToken.split("/").join("=SLH=")
            + fileName;

        return url;
    }
}
