import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenManagerService} from './token-manager.service';
import {LoadingInfo} from './loading-info/loading-info';
import {Endpoints} from '../apps/utils/Endpoints';
import {Company} from '../entity/company.component';
import {Location} from '../entity/location.component';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends LoadingInfo {

  constructor(private _httpClient: HttpClient,
              private _tokenManagerService: TokenManagerService) {
      super();
  }

  async getRegions() {
      const body = {
          authToken: this._tokenManagerService.authToken,
      }

      return this._httpClient.post(
          Endpoints.GET_ALL_REGIONS,
          body,
          {
              headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
          }
      ).toPromise()
  }

  async getProvincesOfRegion(regionId: number) {
      const body = {
          authToken: this._tokenManagerService.authToken,
          districtId: regionId
      }

      return this._httpClient.post(
          Endpoints.GET_ALL_PROVINCES_BY_REGION,
          body,
          {
              headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
          }
      ).toPromise()
  }

  async getCitiesByProvince(provinceId: number) {
      const body = {
          authToken: this._tokenManagerService.authToken,
          provinceId: provinceId
      }

      return this._httpClient.post(
          Endpoints.GET_ALL_CITIES_BY_PROVINCE,
          body,
          {
              headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
          }
      ).toPromise()
  }

  async getCompanyGroups(companyId: number) {
      const body = {
          authToken: this._tokenManagerService.authToken,
          companyId: companyId
      }

      return this._httpClient.post(
          Endpoints.GET_COMPANY_GROUPS,
          body,
          {
              headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
          }
      ).toPromise()
  }

  async addCompany(newCompany: Company) {
      const sites: any[] = [];

      sites.push({
          cap: newCompany.headquarters.postalCode,
          address: newCompany.headquarters.address,
          type: 'primary',
          cityId: newCompany.headquarters.city.id
      });

      newCompany.branches.forEach(branch => {
        sites.push({
            cap: branch.postalCode,
            address: branch.address,
            type: 'secondary',
            cityId: branch.city.id
        });
      });

      const requestCompany = {
          moderatorEmail: newCompany.moderator,
          name: newCompany.name,
          vatNumber: newCompany.iva,
          activities: newCompany.activity,
          sites: sites
      };

      const body = {
          authToken: this._tokenManagerService.authToken,
          company: requestCompany
      };

      return this._httpClient.post(
          Endpoints.CREATE_NEW_COMPANY,
          JSON.stringify(body),
          {
              headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
          }
      ).toPromise();

  }

  async getCompanyById(companyId: number) {
      this.whatIsLoading = 'Caricamento dettagli società';
      this.isLoadingSomething = true;

      const body = {
          authToken: this._tokenManagerService.authToken,
          companyId: companyId
      }

      return this._httpClient.post(
          Endpoints.GET_COMPANY_BY_ID,
          body,
          {
              headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
          }
      ).toPromise()
  }

  async getAllCompanies(page: number, sortType: string, nameFilter: string, moderatorEmail: string,
                        vatNumberFilter: string, filterEnabled: boolean, pageSize: number) {
      this.whatIsLoading = 'Caricamento società';
      this.isLoadingSomething = true;

      let body;

      if(filterEnabled) {
          if (moderatorEmail === '') {
              moderatorEmail = null
          }

          body = {
              authToken: this._tokenManagerService.authToken,
              page: page,
              sortType: sortType,
              nameFilter: nameFilter,
              vatNumberFilter: vatNumberFilter,
              filterEnabled: true,
              pageSize: pageSize,
              moderatorEmail: moderatorEmail
          };
      } else {
          body = {
              authToken: this._tokenManagerService.authToken,
              page: page,
              sortType: sortType,
              filterEnabled: false,
              pageSize: pageSize
          };
      }

      return this._httpClient.post(
            Endpoints.GET_ALL_COMPANIES,
            body,
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/json')
            }
        ).toPromise()
    }

  async deleteCompany(companyId: number) {
        const body = {
            authToken: this._tokenManagerService.authToken,
            companyId: companyId
        }

        return this._httpClient.post(
            Endpoints.DELETE_COMPANY,
            body,
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/json')
            }
        ).toPromise()
    }

  async modifyCompany(editedCompany: Company) {
      this.whatIsLoading = 'Modifica società';
      this.isLoadingSomething = true;

      let body = {
          authToken: this._tokenManagerService.authToken,
          companyId: editedCompany.id,
          name: editedCompany.name,
          vatNumber: editedCompany.iva,
          activities: editedCompany.activity,
          moderatorEmail: editedCompany.moderator
      };

      return this._httpClient.post(
          Endpoints.MODIFY_COMPANY,
          body,
          {
              headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
          }
      ).toPromise()
  }

  async deleteSite(siteId: number) {
      const body = {
          authToken: this._tokenManagerService.authToken,
          siteId: siteId
      };

      return this._httpClient.post(
          Endpoints.DELETE_SITE,
          body,
          {
              headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
          }
      ).toPromise()
  }

  async editSite(editedSite: Location) {
      const body = {
          authToken: this._tokenManagerService.authToken,
          siteId: editedSite.id,
          cap: editedSite.postalCode,
          address: editedSite.address,
          cityId: editedSite.city.id
      };

      return this._httpClient.post(
          Endpoints.EDIT_SITE,
          body,
          {
              headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
          }
      ).toPromise()
  }

  async addSite(branch: Location, companyId: number) {
      const body = {
          authToken: this._tokenManagerService.authToken,
          companyId: companyId,
          cap: branch.postalCode,
          address: branch.address,
          cityId: branch.city.id
      };

      return this._httpClient.post(
          Endpoints.ADD_BRANCH_SITE,
          body,
          {
              headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
          }
      ).toPromise()
  }

  async getAllCompanyModerators() {
    const body = {
        authToken: this._tokenManagerService.authToken
    };

    return  this._httpClient.post(
        Endpoints.GET_COMPANY_MODERATORS, body, {
            headers: new HttpHeaders()
                .set("Content-Type", "application/json")
        }).toPromise();
  }

  async getCompanyEmployees(companyId: number) {
      const body = {
          authToken: this._tokenManagerService.authToken,
          companyId: companyId
      };

      return  this._httpClient.post(
          Endpoints.GET_COMPANY_EMPLOYEES, body, {
              headers: new HttpHeaders()
                  .set("Content-Type", "application/json")
          }).toPromise();
  }

  async getCompanyNames() {
      this.whatIsLoading = 'Caricamento società';
      this.isLoadingSomething = true;

      const body = {
          authToken: this._tokenManagerService.authToken
      };

      return  this._httpClient.post(
          Endpoints.GET_COMPANIES_NAMES, body, {
              headers: new HttpHeaders()
                  .set("Content-Type", "application/json")
          }).toPromise();
  }

}
