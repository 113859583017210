import {WarningDialogComponent} from './warning-dialog.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

export class WarningDialogAdapter {

    constructor() {
    }

    public static showWarning(dialog: MatDialog, title: string, content: string): MatDialogRef<WarningDialogComponent> {

        /**** defining dialog configuration *****/
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;

        /**** defining popup content ****/
        dialogConfig.data = {
            content: content,
            title: title
        };

        /**** opening dialog with desired config *****/
        const dialogRef = dialog.open(WarningDialogComponent, dialogConfig);
        return dialogRef;
    }


}
