import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenManagerService} from './token-manager.service';
import {Endpoints} from '../apps/utils/Endpoints';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordRecoverService {

    constructor(private httpClient: HttpClient, private userService: UserService) {

    }

    async sendRecoverEmail(username: string) {

        this.userService.isLoadingSomething = true;
        this.userService.whatIsLoading = 'Invio email di recupero password';

        let promise;
        const body = {
            username: username
        };

        await this.httpClient.post(
            Endpoints.SEND_RECOVERY_EMAIL, body, {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
        }).toPromise().then(response => {
            this.userService.isLoadingSomething = false;
            promise = response;
        }).catch(error => {
            this.userService.isLoadingSomething = false;
            promise = error;
        });
        return promise;
    }

    async resetPasswordByPToken(pToken: string, password: string) {

        let promise;
        const body = {
            pwdToken: pToken,
            password: password
        };

        await this.httpClient.post(
            Endpoints.RECOVER_PASSWORD, body, {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
        }).toPromise().then(response => {
            promise = response;
        }).catch(error => {
            promise = error;
        });
        return promise;
    }

}
