<div id="show-communication" class="page-layout blank">
    <div class="spin-loading-container" *ngIf="communicationService.isLoadingSomething">
        <div class="loading-wrapper" fxLayout="column">
            <mat-spinner class="loading-spinner"></mat-spinner>
            <span class="loading-text">{{communicationService.whatIsLoading}}</span>
        </div>
    </div>
    <mat-dialog-content fxFlexFill fxLayout="column" id="show-communication-form" *ngIf="!communicationService.isLoadingSomething">

        <div fxLayout="row" class="title-container dialog-title title-div">
            <mat-label fxFlex class="title">
                Visualizza comunicazione
            </mat-label>
        </div>
        <div class="spacer"></div>
        <div fxLayout="row" class="form-container">
            <div class="card top-card">
                <div class="card-preview">
                    <div class="my-card">
                        <div fxFill fxFlex fxLayout="column">
                            <div fxLayout="row" class="card-header">
                                <mat-label class="card-title">
                                    Dati generali
                                </mat-label>
                            </div>
                            <div fxLayout="row" class="base-form-container">
                                <div fxFill fxFlex fxLayout="column" class="left-form">
                                    <mat-form-field class="fields" appearance="fill">
                                        <mat-label>Titolo</mat-label>
                                        <input matInput [disabled]="true" [ngModel]="title"/>
                                    </mat-form-field>
                                    <mat-form-field class="fields text-area-field" appearance="fill">
                                        <mat-label>Messaggio</mat-label>
                                        <textarea matInput [disabled]="true" [ngModel]="message"></textarea>
                                    </mat-form-field>
                                </div>
                                <div fxFill fxFlex fxLayout="column" class="right-form">
                                    <div fxLayout="row">
                                        <mat-form-field class="priority-field" appearance="fill" *ngIf="false">
                                            <mat-label>Priorità</mat-label>
                                            <mat-select [disabled]="true" [ngModel]="priority">
                                                <mat-option [value]="'low'">Bassa</mat-option>
                                                <mat-option [value]="'medium'">Media</mat-option>
                                                <mat-option [value]="'high'">Alta</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="doc-type-field" appearance="fill">
                                            <mat-label>Tipologia</mat-label>
                                            <mat-select [disabled]="true" [ngModel]="type">
                                                <mat-option *ngFor="let type of types" [disabled]="type.id === null"
                                                            [value]="type.id">
                                                    {{type.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div fxLayout="column" class="list-selector-container">
            <div fxFill fxFlex fxLayout="row">
                <div fxFlex fxFill class="card top-card">
                    <div class="card-preview">
                        <div class="my-card">
                            <div fxFill fxFlex fxLayout="column">
                                <div fxLayout="row" class="card-header">
                                    <mat-label class="card-title">
                                        Dettaglio presa visione
                                    </mat-label>
                                </div>

                                <div fxFill fxFlex fxLayout="column" class="table-container">
                                    <table mat-table [dataSource]="readingUsers" class="mat-elevation-z8 table-style">
                                        <ng-container matColumnDef="company">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Società
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ ellipsify(element.user.companyNames?.toString()) || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="group">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Gruppo
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.user.groupName || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="qualification">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Qualifica
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.user.qualificationName || '-' }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="surname">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Cognome
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.user.surname }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="name">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Nome
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.user.name }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="fiscalCode">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Codice fiscale
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.user.fiscalCode }}
                                            </td>
                                        </ng-container>
                                        <ng-container matColumnDef="read">
                                            <th mat-header-cell *matHeaderCellDef>
                                                Letto
                                            </th>
                                            <td mat-cell *matCellDef="let element">
                                                <mat-icon class="green-icon" *ngIf="element.read"
                                                          matTooltip="{{element.readingDate}}"
                                                          [matTooltipPosition]="toolTipPosition" [matTooltipShowDelay]="500"
                                                          matTooltipClass="custom-tooltip">
                                                    done
                                                </mat-icon>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row class="table-header" *matHeaderRowDef="
                                                    readingConfirmationColumns;
                                                    sticky: true
                                                "></tr>
                                        <tr mat-row *matRowDef="
                                                    let row;
                                                    columns: readingConfirmationColumns
                                                "></tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" class="buttons-container" *ngIf="!communicationService.isLoadingSomething">
        <button mat-raised-button color="accent" class="confirm-button" aria-label="EXIT" (click)="exportDocumentInfo()">
            Esporta
        </button>
        <button mat-raised-button class="cancel-button exit-button" aria-label="EXIT" (click)="closeDialog()">
            Esci
        </button>
    </mat-dialog-actions>
</div>
