import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {WarningDialogComponent} from './main/apps/utils/warning-dialog/warning-dialog.component';
import {RecoverDialog} from './main/pages/authentication/login/recover-dialog/recover-dialog.component';
import {
    ShowDocumentDialog
} from './main/apps/documents-management/list-documents/show-document-dialog/show-document-dialog.component';
import {ShowTestDialog} from './main/apps/tests-management/list-tests/show-test-dialog/show-test-dialog.component';
import {ShowUserDialog} from './main/apps/users-management/list-users/show-user-dialog/show-user-dialog.component';
import {
    EditGroupDialogComponent
} from './main/apps/groups-manager/list-groups/edit-group-dialog/edit-group-dialog.component';
import {ConfirmDialogModule} from './main/apps/utils/confirm-dialog/confirm-dialog.component';
import {MatSnackBarContainer, SimpleSnackBar} from '@angular/material/snack-bar';
import {
    ShowCommunicationDialogComponent
} from './main/apps/comms-management/list-communications/show-communication-dialog/show-communication-dialog.component';
import {ToolbarComponent} from './layout/components/toolbar/toolbar.component';

const appRoutes: Routes = [
    {
        path        : 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    },
    {
        path        : 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path        : 'ui',
        loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule)
    },
    {
        path      : '**',
        redirectTo: 'pages/auth/login'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {useHash: true, scrollPositionRestoration: 'enabled' }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule
    ],
    providers : [
        ToolbarComponent
    ],
    bootstrap   : [
        AppComponent
    ],
    entryComponents: [
        WarningDialogComponent, RecoverDialog, ShowDocumentDialog, ShowTestDialog,
        ShowUserDialog, EditGroupDialogComponent, ConfirmDialogModule, MatSnackBarContainer,
        SimpleSnackBar, ShowCommunicationDialogComponent
    ]
})
export class AppModule
{
}
