import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FuseConfigService} from '@fuse/services/config.service';
import {resolve} from 'dns';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PasswordRecoverService} from '../../../../services/password-recover.service';
import {WarningDialogAdapter} from '../../../../apps/utils/warning-dialog/warning-dialog-adapter';
import {EMAIL_REGEX, METHOD_NOT_ALLOWED, STATUS_UNAUTHORIZED} from '../../../../apps/utils/commons.component';


@Component({
    selector: 'rec',
    templateUrl: './recover-dialog.component.html',
    styleUrls: ['./recover-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RecoverDialog implements OnInit {
    recoverForm: FormGroup;
    infoMessage: String;
    emailErrorStatus: boolean = false;
    disableButton: boolean = false;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _dialogRef: MatDialogRef<RecoverDialog>,
        private _recoverService: PasswordRecoverService,
        private _dialog: MatDialog
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.recoverForm = this._formBuilder.group({
            emailAddress: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)])
        });
    }

    /***** on send click *****/
    sendRecoverMail(): void {
        /***** if field is empty */
        if (this.recoverForm.get('emailAddress').hasError('required')) {
            this.infoMessage = 'Attenzione! Campi obbligatori non compilati.';
            return;
        }

        /***** if email address in te field is not valid *****/
        if (this.recoverForm.get('emailAddress').hasError('pattern')) {
            this.infoMessage = 'Attenzione! Campo non popolato correttamente.';
            return;
        }

        this.infoMessage = '';
        this.disableButton = true;


        this._recoverService.sendRecoverEmail(this.recoverForm.get('emailAddress').value).then(result => {
            this.disableButton = false;

            if (result['status'] === true) {
                WarningDialogAdapter.showWarning(this._dialog, 'Recupero password', 'Email di recupero inviata all\'indirizzo:\n' +
                    this.recoverForm.get('emailAddress').value);
                this._dialogRef.close();
            } else if (result['status'] === METHOD_NOT_ALLOWED || result['status'] === STATUS_UNAUTHORIZED) {
                this.infoMessage = result['error']['result'];
            } else {
                WarningDialogAdapter.showWarning(this._dialog, '', result['error']['details']);
            }
        });
    }

    /***** on cancel clicked *****/
    cancel(): void {
        this._dialogRef.close();
    }
}
