import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";

import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {ResetPasswordComponent} from '../../../pages/authentication/reset-password/reset-password.component';


@Component({
    selector: "warn",
    templateUrl: "./warning-dialog.component.html",
    styleUrls: ["./warning-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})

export class WarningDialogComponent implements OnInit {

    dialogTitle: string;
    dialogContent: string;

    /**
     * Constructor
     *
     */
    constructor(
        public dialogRef: MatDialogRef<WarningDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: any
    ) {
        this.dialogTitle = dialogData.title;
        this.dialogContent = dialogData.content;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }

    public okClick() {
        this.dialogRef.close();
        if(ResetPasswordComponent.fromMobile) {
            window.close();
        }
    }

}
