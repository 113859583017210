import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

export const ASCII_CAPITAL_CHAR_LOWER_BOUND: number = 64;
export const ASCII_CAPITAL_CHAR_HIGHER_BOUND: number = 91;
export const ASCII_CHAR_LOWER_BOUND: number = 96;
export const ASCII_CHAR_HIGHER_BOUND: number = 123;
export const ASCII_ACCENTED_CHARACTER_LOWER_BOUND: number = 223;
export const ASCII_ACCENTED_CHARACTER_HIGHER_BOUND: number = 256;

export const ASCII_BACKSPACE: number = 8;
export const ASCII_SPACE: number = 32;
export const ASCII_POINT: number = 46;
export const ASCII_COMMA: number = 44;
export const ASCII_APOSTROPHE: number = 39;

export const ASCII_NUMBER_LOWER_BOUND: number = 47;
export const ASCII_NUMBER_HIGHER_BOUND: number = 58;

export const STATUS_UNAUTHORIZED : number = 401;
export const METHOD_NOT_ALLOWED : number = 405;
export const STATUS_FORBIDDEN : number = 403;
export const CONFLICT : number = 409;

/*** Toast loaction ***/
export const TOAST_HORIZONTAL_POSITION: MatSnackBarHorizontalPosition = 'center';
export const TOAST_VERTICAL_POSITION: MatSnackBarVerticalPosition = 'bottom';

/*** User sorting const ***/
export const SORT_BY_SURNAME: string = 'surname';
export const SORT_BY_NAME: string = 'name';
export const SORT_BY_FISCALCODE: string = 'fiscal_code';

/*** gruppi sorting const ***/
export const GROUP_SORT_BY_NAME: string = 'name';
export const GROUP_SORT_BY_MODERATOR: string = 'moderator';
export const GROUP_SORT_BY_COMPANY: string = 'company';

/*** documents sorting const ***/
export const DOCUMENT_SORT_BY_TITLE: number = 0;
export const DOCUMENT_SORT_BY_SHARE_DATE: number = 1;

export const COMMUNICATION_SORT_BY_TITLE: number = 0;
export const COMMUNICATION_SORT_BY_SHARE_DATE: number = 1;
export const COMMUNICATION_SORT_BY_PRIORITY: number = 2;

export const ASSOCIATE_ONLY = 0;
export const NOT_ASSOCIATE_ONLY = 1;
export const EVERYONE = 2;

export const DIPENDENTE = 0;
export const LAVORATORE_AUTONOMO = 1;
export const TUTTI = 2;

/*** tests sorting const ***/
export const TEST_SORT_BY_TITLE: number = 0;
export const TEST_SORT_BY_TYPE: number = 1;
export const TEST_SORT_BY_SHARE_DATE: number = 2;

/*** tests const ***/
export const MULTIPLE_CHOICES_QUESTION = 0;
export const OPEN_QUESTION = 1;

export const TEST_TYPE_SURVEY = 0;
export const TEST_TYPE_VALUTATION_TEST = 1;

export const EVERYDAY = 0;
export const FERIAL_DAYS = 1;
export const WEEKLY = 2;
export const MONTHLY = 3;
export const YEARLY = 4;
export const NEVER = 5;

export const BASE_REGEX = '^[a-zA-Z0-9 ,.\'éèòàùì]*$';
export const TEXT_AND_NUMBERS_REGEX = '^[a-zA-Z0-9]*$';
export const FISCAL_CODE_REGEX = '^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$';
export const PARTITA_IVA_REGEX = '[0-9]{11}';
export const FREETEXT_REGEX = /^[a-zA-Z0-9,èìàùòé\\.\n'()"$%/{}£=_°§^?ç|@#!\-&:;<+>*€\[\]][a-zA-Z0-9èéàòìù ,\\.\n'()"$%/{}=_°!?£ç€|§^@#\-&:;<+>*\[\]]+$/;
export const FREETEXT_CHARACTER_REGEX = /^[a-zA-Z0-9èìàùòé$&+:;@#|'=_°§^<>!?"£/ç€.\\*()%-][a-zA-Z0-9èéàòìù ,\\.\n$&+;@#|'=!?"£/ç€_°§:<>^*()%-]+$/;
export const EMAIL_REGEX = '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';
export const GENERAL_NAME_REGEX = '^[a-zA-Zòàèùìé]+(([\', -][a-zA-Zòàèùìé ])?[a-zA-Zòàèùìé ]*)*$';
export const PHONE_NUMBERS_ONLY_REGEX = '^[0-9]{5,11}$';
export const TEXT_ONLY_REGEX = '^[a-zA-Z]*$';
export const NUMBERS_ONLY_REGEX = '^[0-9]+$';
export const CAP_REGEX = '^(V-|I-)?[0-9]{5}$';
export const TEXT_AND_SYMBOL_REGEX = /^[a-zA-ZàáâäãåąæœčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ðğ][a-zA-Z0-9àáâäãåąæœčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ðğ /€$£e=_!?"|°§{}^%()&*:;@#+<>.,'¿¡-]*$/;
export const TEXT_AND_SYMBOL_FILTER_REGEX = /^[^ ][a-zA-Z0-9àáâäãåąæœčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ðğ /€=_°§^$£@#e%!?"|()&*:.+<>,'¿¡-]*$/;

