<div id="recover" fxLayout="column">
    <div class="spin-loading-container spinner" *ngIf="disableButton">
        <div class="loading-wrapper">
            <mat-spinner class="loading-spinner"></mat-spinner>
        </div>
    </div>
    <div id="recover-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="recover-form">
            <form name="recoverForm" [formGroup]="recoverForm" novalidate>

                <mat-form-field fxFill appearance="outline">
                    <mat-label>Indirizzo email</mat-label>
                    <input matInput formControlName="emailAddress">
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                </mat-form-field>
                <mat-label class="error-label">
                    {{infoMessage}}
                </mat-label>
                <div fxLayout="row" fxLayout.xs="column" style="display:flex; flex-wrap: wrap; justify-content: space-between;">
                <button mat-raised-button color="accent" class="submit-button" aria-label="SEND"
                        [disabled]="disableButton" (click)="sendRecoverMail()">
                    Invia
                </button>
                <div class="spacer"></div>
                <button mat-raised-button class="cancel-button" aria-label="CANCEL"
                        [disabled]="disableButton" (click)="cancel()">
                    Annulla
                </button>
            </div>
            </form>
        </div>
    </div>
</div>
