import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenManagerService} from './token-manager.service';
import {LoadingInfo} from './loading-info/loading-info';
import {Communication, COMMUNICATION_TYPE} from '../entity/communication.component';
import {Endpoints} from '../apps/utils/Endpoints';
import {
    COMMUNICATION_SORT_BY_PRIORITY, COMMUNICATION_SORT_BY_SHARE_DATE,
    COMMUNICATION_SORT_BY_TITLE,
    TEST_SORT_BY_SHARE_DATE,
    TEST_SORT_BY_TITLE,
    TEST_SORT_BY_TYPE
} from '../apps/utils/commons.component';

@Injectable({
  providedIn: 'root'
})
export class CommunicationsService extends LoadingInfo {



    constructor(private _httpClient: HttpClient,
                private _tokenManagerService: TokenManagerService) {
        super();
    }

    async addCommunication(communication: Communication) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento nuova comunicazione';
        let promise;

        const body = {
            authToken: this._tokenManagerService.authToken,
            communication: {
                title: communication.title,
                message: communication.message,
                priority: 'low',
                typeId: communication.type
            },
            communicationTarget: {
                workerType: communication.workerType,
                associatesOnly: communication.associateOnly,
                qualifications: communication.qualifications,
                personalUsers: communication.users,
                usersGroups: communication.usersGroups,
                companies: communication.companies
            }
        };

        await this._httpClient.post(
            Endpoints.ADD_NEW_COMMUNICATION, body, {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/json')
            }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    async getCommunicationsByPage(page: number, sortType: string, sortBy: number, pageSize: number, filter?: any) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento comunicazioni';
        let promise;
        let body;
        let sortValue;

        switch (sortBy) {
            case COMMUNICATION_SORT_BY_TITLE:
                sortValue = 'title';
                break;
            case COMMUNICATION_SORT_BY_PRIORITY:
                sortValue = 'priority';
                break;
            case COMMUNICATION_SORT_BY_SHARE_DATE:
                sortValue = 'share_date';
                break;
            default:
                sortValue = 'share_date';
        }

        if(filter != null && filter != undefined) {
            body = {
                authToken: this._tokenManagerService.authToken,
                page: page,
                sortType: sortType,
                sortBy: sortValue,
                pageSize: pageSize,
                filter: filter
            };
        }else {
            body = {
                authToken: this._tokenManagerService.authToken,
                page: page,
                sortType: sortType,
                sortBy: sortValue,
                pageSize: pageSize
            };
        }

        await this._httpClient.post(
            Endpoints.GET_COMMUNICATIONS_BY_PAGE, body, {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/json')
            }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    async getCommunicationById(communicationId: number) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento dettagli comunicazione';
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken,
            communicationId: communicationId
        };

        await this._httpClient.post(
            Endpoints.GET_COMMUNICATION_DETAILS, body, {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/json')
            }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    async getCommunicationsTypes() {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento';
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken
        };

        await this._httpClient.post(
            Endpoints.GET_COMMUNICATIONS_TYPES, body, {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/json')
            }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }
}
