import {MatSnackBar} from '@angular/material/snack-bar';
import {TOAST_HORIZONTAL_POSITION, TOAST_VERTICAL_POSITION} from './commons.component';

export class ToastManager {


    /**
     * Constructor
     *
     */
    constructor(private _snackBar: MatSnackBar){

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }

    public showToast(message: string) {
        if (message !== '') {
            this._snackBar.open(message, null, {
                panelClass: ['snack-bar'],
                duration: 2000,
                horizontalPosition: TOAST_HORIZONTAL_POSITION,
                verticalPosition: TOAST_VERTICAL_POSITION,
            });
        }
    }
}
