import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenManagerService} from './token-manager.service';
import {Group} from '../entity/group.component';
import {Document} from '../entity/document.component';
import {LoadingInfo} from './loading-info/loading-info';
import {Endpoints} from '../apps/utils/Endpoints';
import {
    DOCUMENT_SORT_BY_SHARE_DATE,
    DOCUMENT_SORT_BY_TITLE,
    TEST_SORT_BY_SHARE_DATE,
    TEST_SORT_BY_TITLE,
    TEST_SORT_BY_TYPE
} from '../apps/utils/commons.component';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService extends LoadingInfo {

    constructor(private _httpClient: HttpClient, private _tokenManagerService: TokenManagerService,) {
        super();
    }

    public async uploadDocumentInfo(document: Document) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento informazioni file';
        let promise;

        const body = {
            authToken: this._tokenManagerService.authToken,
            document: {
                title: document.title,
                description: document.description,
                filePath: document.filePath,
                type: document.type
            },
            documentTarget: {
                workerType: document.workerType,
                associatesOnly: document.associateOnly,
                qualifications: document.qualifications,
                personalUsers: document.users,
                usersGroups: document.usersGroups,
                companies: document.companies
            }
        };

        await this._httpClient.post(
            Endpoints.SHARE_DOCUMENT, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async getAvailableTarget() {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento dati';
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken
        };

        await this._httpClient.post(
            Endpoints.GET_AVAILABLE_TARGETS, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async getAllDocuments() {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento documenti';
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken
        };

        await this._httpClient.post(
            Endpoints.GET_ALL_DOCUMENTS, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async getDocumentsByPage(page: number, sortType: string, sortBy: number, pageSize: number, filter?: any) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento documenti';
        let promise;
        let body;
        let sortValue;

        switch (sortBy) {
            case DOCUMENT_SORT_BY_TITLE:
                sortValue = 'title';
                break;
            case DOCUMENT_SORT_BY_SHARE_DATE:
                sortValue = 'share_date';
                break;
            default:
                sortValue = 'title';
        }

        if(filter != null && filter != undefined) {
            body = {
                authToken: this._tokenManagerService.authToken,
                page: page,
                sortType: sortType,
                sortBy: sortValue,
                pageSize: pageSize,
                filter: filter
            };
        }else {
            body = {
                authToken: this._tokenManagerService.authToken,
                page: page,
                sortType: sortType,
                sortBy: sortValue,
                pageSize: pageSize
            };
        }

        await this._httpClient.post(
            Endpoints.GET_DOCUMENTS_BY_PAGE, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async getDocumentDetails(documentId: string) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Caricamento dettagli documento';
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken,
            pathId: documentId
        };

        await this._httpClient.post(
            Endpoints.GET_DOCUMENT_DETAILS, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async deleteDocument(documentId: string) {
        this.isLoadingSomething = true;
        this.whatIsLoading = 'Eliminazione documento';
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken,
            pathId: documentId
        };

        await this._httpClient.post(
            Endpoints.DELETE_DOCUMENT, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }
}
