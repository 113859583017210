<div id="show-user" class="page-layout blank">

    <div class="spin-loading-container" *ngIf="userService.isLoadingSomething
    || groupService.isLoadingSomething || this.companyService.isLoadingSomething">
        <div class="loading-wrapper" fxLayout="column">
            <mat-spinner class="loading-spinner"></mat-spinner>
            <span class="loading-text">{{userService.whatIsLoading}}</span>
        </div>
    </div>
    <mat-dialog-content fxFlexFill fxLayout="column" id="show-user-form" *ngIf="!userService.isLoadingSomething &&
    !groupService.isLoadingSomething && !companyService.isLoadingSomething">
        <div fxFlex="5" fxLayout="row" class="title-container dialog-title">
            <!-- if is in edit mode -->
            <mat-label fxFlex class="title" *ngIf="userData.editMode">
                Modifica utente: {{userData.user.surname}} {{userData.user.name}}
            </mat-label>
            <!-- if is not in edit mode -->
            <mat-label fxFlex class="title" *ngIf="!userData.editMode">
                Dettaglio utente: {{userData.user.surname}} {{userData.user.name}}
            </mat-label>

            <mat-label fxFlex class="warn-message">
                {{errorMessage}}
            </mat-label>
        </div>
        <div fxLayout="row" class="base-form-container">
            <div fxFlex fxFill class="card">
                <div class="card-preview">
                    <div class="my-card">
                        <div fxFill fxFlex fxLayout="column">
                            <div fxLayout="row" class="card-header">
                                <mat-label class="card-title">
                                    Dati generali
                                </mat-label>
                            </div>

                            <form name="addUserForm" [formGroup]="editUserForm">
                                <!-- if is in edit mode -->
                                <div fxLayout="row" class="first-form-row">
                                    <div class="spacer"></div>
                                    <mat-form-field fxFlex fxFill appearance="fill">
                                        <mat-label>Cognome</mat-label>
                                        <input matInput formControlName="surname" (paste)="onPasteTextOnly($event)" (keypress)="textOnly($event)" required />
                                    </mat-form-field>
                                    <div class="spacer"></div>
                                    <mat-form-field fxFlex fxFill appearance="fill">
                                        <mat-label>Nome</mat-label>
                                        <input matInput formControlName="name" (paste)="onPasteTextOnly($event)" (keypress)="textOnly($event)" required />
                                    </mat-form-field>
                                    <div class="spacer"></div>
                                    <mat-form-field fxFlex fxFill appearance="fill">
                                        <mat-label>Codice fiscale</mat-label>
                                        <input matInput formControlName="fiscalCode" required />
                                    </mat-form-field>
                                    <div class="spacer"></div>
                                    <mat-form-field fxFlex fxFill appearance="fill">
                                        <mat-label>Email</mat-label>
                                        <input matInput formControlName="emailAddress" required />
                                    </mat-form-field>
                                    <div class="spacer"></div>
                                </div>
                                <div fxLayout="row" class="second-form-row">
                                    <div class="spacer"></div>
                                    <mat-form-field fxFlex fxFill appearance="fill" *ngIf="isGroupModerator || isCompanyModerator">
                                        <mat-label>Tipologia account</mat-label>
                                        <mat-select formControlName="accountMode" required [disabled]="true"
                                                    (selectionChange)="onSelectRole($event.value)"
                                                    matTooltip="Impossibile modificare il ruolo{{'\n'}}di questo utente"
                                                    [matTooltipPosition]="toolTipPosition" [matTooltipShowDelay]="500"
                                                    matTooltipClass="custom-tooltip">
                                            <mat-option *ngIf="this.userService.loggedUser.role === 'RBAC_ADMIN'||
                                        this.userService.loggedUser.role === 'AMMINISTRATORE'"
                                                value="AMMINISTRATORE">Amministratore</mat-option>
                                            <mat-option value="LAVORATORE">Lavoratore</mat-option>
                                            <mat-option value="MODERATORE"
                                                        [disabled]="this.userService.loggedUser.role !== 'MODERATORE'">Moderatore</mat-option>
                                            <mat-option value="RESPONSABILE">Responsabile</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field fxFlex fxFill appearance="fill" *ngIf="!isCompanyModerator && !isGroupModerator">
                                        <mat-label>Tipologia account</mat-label>
                                        <mat-select formControlName="accountMode" required (selectionChange)="onSelectRole($event.value)">
                                            <mat-option *ngIf="this.userService.loggedUser.role === 'RBAC_ADMIN'||
                                        this.userService.loggedUser.role === 'AMMINISTRATORE'"
                                                value="AMMINISTRATORE">Amministratore</mat-option>
                                            <mat-option value="LAVORATORE">Lavoratore</mat-option>
                                            <mat-option value="MODERATORE" *ngIf="this.userService.loggedUser.role !== 'MODERATORE'">Moderatore</mat-option>
                                            <mat-option value="RESPONSABILE">Responsabile</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="spacer"></div>
                                    <mat-form-field fxFlex fxFill appearance="fill">
                                        <mat-label>Tipologia lavoratore</mat-label>
                                        <mat-select formControlName="workerType" required (selectionChange)="onSelectTypeChange()">
                                        <mat-option [value]="0">Dipendente</mat-option>
                                            <mat-option [value]="1" *ngIf="canBeSelfEmployed">Lavoratore autonomo</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="spacer"></div>
                                    <mat-form-field fxFlex fxFill appearance="fill">
                                        <mat-label>Qualifica</mat-label>
                                        <mat-select formControlName="qualification" required>
                                            <mat-option *ngFor="let qual of qualifications"
                                                        [value]="qual.id">{{qual.title}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="spacer"></div>
                                    <mat-form-field fxFlex fxFill appearance="fill">
                                        <mat-label>Socio</mat-label>
                                        <mat-select formControlName="isAssociated" required>
                                            <mat-option [value]="true">Sì</mat-option>
                                            <mat-option [value]="false">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="spacer"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div fxFlex="85" fxLayout="row" class="list-selector-container">
            <div fxFlex fxFill class="card">
                <div class="card-preview">
                    <div class="my-card">
                        <div fxFill fxFlex fxLayout="column">
                            <div fxLayout="row" class="card-header">
                                <mat-label class="card-title">
                                    Recapiti
                                </mat-label>
                            </div>
                            <div fxLayout="row" [formGroup]="contactsForm">
                                <div class="spacer"></div>
                                <mat-form-field fxFlex class="card-form" *ngIf="userData.editMode" appearance="fill">
                                    <mat-label>Tipo recapito</mat-label>
                                    <mat-select formControlName="type" [disabled]="!userData.editMode">
                                        <mat-option value="Cellulare">Cellulare</mat-option>
                                        <mat-option value="Fisso">Fisso</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="spacer"></div>
                                <mat-form-field fxFlex class="card-form" *ngIf="userData.editMode" appearance="fill">
                                    <mat-label>Recapito</mat-label>
                                    <input matInput formControlName="contact" (paste)="onPasteNumbersOnly($event)" (keypress)="numbersOnly($event)"
                                        [disabled]="!userData.editMode" />
                                </mat-form-field>
                                <div class="spacer"></div>
                                <button mat-icon-button class="add-button" *ngIf="userData.editMode"
                                    (click)="addContact()">
                                    <mat-icon inline="true" aria-label="ADD" class="add-button-icon" color="accent">
                                        add_circle</mat-icon>
                                </button>
                                <div class="spacer"></div>
                            </div>
                            <div fxFill fxFlex fxLayout="column" class="table-container" [ngClass]="{'table-container-scroll' : contactsStructure.length > 5}">
                                    <table mat-table [dataSource]="contacts" class="mat-elevation-z8 table-style">
                                        <ng-container matColumnDef="type">
                                            <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="contact">
                                            <th mat-header-cell *matHeaderCellDef> Recapito </th>
                                            <td mat-cell *matCellDef="let element"> {{element.number}} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="actions" *ngIf="userData.editMode">
                                            <th mat-header-cell *matHeaderCellDef> Azioni </th>
                                            <td mat-cell *matCellDef="let element">
                                                <button mat-icon-button *ngIf="element.actions"
                                                    [disabled]="!userData.editMode"
                                                    (click)="showConfirmPopup(element.number, 0)"
                                                    matTooltip="Elimina recapito {{'\n' + element.contact}}"
                                                    [matTooltipPosition]="toolTipPosition" [matTooltipShowDelay]="500"
                                                    matTooltipClass="custom-tooltip">
                                                    <mat-icon color="warn">
                                                        remove
                                                    </mat-icon>
                                                </button>
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row class="table-header"
                                            *matHeaderRowDef="contactsColumnsNames; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: contactsColumnsNames;"></tr>
                                    </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spacer"></div>
            <div fxFlex fxFill class="card">
                <div class="card-preview" *ngIf="this.companies.data.length >= 1 && this.companies.data[0].id
                && this.userRole === 'MODERATORE'">
                    <div class="my-card">
                        <div fxFill fxFlex fxLayout="column">
                            <div fxLayout="row" class="card-header">
                                <mat-label class="card-title">
                                    Società
                                </mat-label>
                            </div>
                            <div class="spacer"></div>
                            <div fxFlex fxLayout="column" class="table-container">
                                <table mat-table [dataSource]="companies"
                                       class="mat-elevation-z8 table-style">
                                    <ng-container matColumnDef="company">
                                        <th mat-header-cell *matHeaderCellDef>
                                            Società
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.name }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef>
                                            Azioni
                                        </th>
                                        <td mat-cell *matCellDef="let element">

                                        </td>
                                    </ng-container>

                                    <tr mat-header-row class="table-header" *matHeaderRowDef="
                                                companyColumns;
                                                sticky: true
                                            "></tr>
                                    <tr mat-row *matRowDef="
                                                let row;
                                                columns: companyColumns
                                            "></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer"></div>
        <div fxFlex="85" fxLayout="row" class="list-selector-container"
             *ngIf="(editUserForm.get('accountMode').value === 'LAVORATORE'
        || editUserForm.get('accountMode').value === 'RESPONSABILE')">
            <div fxFlex fxFill class="card">
                <div class="card-preview">
                    <div class="my-card">
                        <div fxFill fxFlex fxLayout="column">
                            <div fxLayout="row" class="card-header">
                                <mat-label class="card-title" *ngIf="!(this.companies.data.length === 1 && this.companies.data[0].id === null && this.emptyError)">
                                    Società
                                </mat-label>

                                <mat-label class="card-title" *ngIf="(this.companies.data.length === 1 && this.companies.data[0].id === null && this.emptyError)" style="color: red">
                                    Società
                                </mat-label>
                            </div>
                            <div fxLayout="row" [formGroup]="selectedCompaniesForm">
                                <div class="spacer"></div>
                                <div style="width: 100%"
                                     *ngIf="(this.companiesData.length === 1 && this.companiesData[0].id === null
                                    && this.editUserForm.get('workerType').value === 0) || this.editUserForm.get('workerType').value === 1
                                    || this.editUserForm.get('workerType').value === null">
                                    <mat-form-field fxFlex="90" class="card-form" appearance="fill" *ngIf="userData.editMode">
                                        <mat-label>Società</mat-label>
                                        <mat-select formControlName="selectedCompany" [required]="isCompanyMandatory" [disabled]="this.editUserForm.get('workerType').value === null">
                                            <mat-option *ngFor="let company of availableCompanies"
                                                        [value]="company" [disabled]="company.id == null || isOptionAlreadySelected(7, company)">
                                                {{company.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div class="spacer" *ngIf="userData.editMode"></div>
                                    <button mat-icon-button class="add-button" (click)="addCompany()" *ngIf="userData.editMode">
                                        <mat-icon inline="true" aria-label="ADD" class="add-button-icon">add_circle</mat-icon>
                                    </button>
                                    <div class="spacer"></div>
                                </div>
                            </div>
                            <div fxFlex fxLayout="column" class="table-container">
                                <table mat-table [dataSource]="companies"
                                       class="mat-elevation-z8 table-style">
                                    <ng-container matColumnDef="company">
                                        <th mat-header-cell *matHeaderCellDef>
                                            Società
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.name }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef>
                                            Azioni
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <button mat-icon-button *ngIf="element.actions && !isGroupModerator"
                                                    (click)="showConfirmPopup(element, 7)"
                                                    matTooltip="Elimina {{'\n' + element.name}}"
                                                    [matTooltipPosition]="toolTipPosition"
                                                    [matTooltipShowDelay]="500"
                                                    matTooltipClass="custom-tooltip">
                                                <mat-icon color="warn">
                                                    remove
                                                </mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row class="table-header" *matHeaderRowDef="
                                                companyColumns;
                                                sticky: true
                                            "></tr>
                                    <tr mat-row *matRowDef="
                                                let row;
                                                columns: companyColumns
                                            "></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="spacer"></div>
            <div fxFlex fxFill class="card">
                <div class="card-preview">
                    <div class="my-card">
                        <div fxFill fxFlex fxLayout="column">
                            <div fxLayout="row" class="card-header">
                                <mat-label class="card-title">
                                    Gruppi
                                </mat-label>
                            </div>
                            <div fxLayout="row" [formGroup]="addGroupForm">
                                <div class="spacer"></div>
                                <mat-form-field fxFlex class="card-form" *ngIf="userData.editMode" appearance="fill">
                                    <mat-label>Gruppo</mat-label>
                                    <mat-select formControlName="id">
                                        <mat-option *ngFor="let groupSelect of availableGroups"
                                                    [disabled]="!groupSelect.id || isOptionAlreadySelected(3, groupSelect)"
                                                    [value]="groupSelect.id">{{groupSelect.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div class="spacer"></div>

                                <button mat-icon-button class="add-button" *ngIf="userData.editMode"
                                        (click)="addGroup()">
                                    <mat-icon inline="true" aria-label="ADD" class="add-button-icon" color="accent">
                                        add_circle</mat-icon>
                                </button>
                                <div class="spacer"></div>
                            </div>
                            <div fxFill fxFlex fxLayout="column" class="table-container" [ngClass]="{'table-container-scroll' : groupsBaseData.length > 5}">
                                <table mat-table [dataSource]="groups" class="mat-elevation-z8 table-style">
                                    <ng-container matColumnDef="company">
                                        <th mat-header-cell *matHeaderCellDef> Società </th>
                                        <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
                                    </ng-container>

                                    <!-- group name column -->
                                    <ng-container matColumnDef="group">
                                        <th mat-header-cell *matHeaderCellDef> Gruppo </th>
                                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                    </ng-container>

                                    <!-- Actions Column -->
                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef> Azioni </th>
                                        <td mat-cell *matCellDef="let element">
                                            <button mat-icon-button *ngIf="element.actions && userData.editMode
                                            && (userEmail !== element.responsibleEmailAddress)"
                                                    (click)="showConfirmPopup(element.name, 1)"
                                                    matTooltip="Elimina{{'\n' + element.name}}"
                                                    [matTooltipPosition]="toolTipPosition" [matTooltipShowDelay]="500"
                                                    matTooltipClass="custom-tooltip">
                                                <mat-icon color="warn">
                                                    remove
                                                </mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row class="table-header"
                                        *matHeaderRowDef="groupsColumns; sticky: true"></tr>
                                    <tr mat-row *matRowDef="let row; columns: groupsColumns;"></tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" class="buttons-container" *ngIf="!userService.isLoadingSomething && !groupService.isLoadingSomething">
        <!-- If is in edit mode -->
        <button mat-raised-button color="accent" class="confirm-button" aria-label="ADD" *ngIf="userData.editMode"
            (click)="saveChanges()">
            Salva
        </button>
        <button mat-raised-button class="cancel-button" aria-label="CANCEL" *ngIf="userData.editMode"
            (click)="closePopup()">
            Annulla
        </button>
        <!-- if is not in edit mode -->
        <button mat-raised-button color="accent" class="confirm-button exit-button" aria-label="CANCEL" *ngIf="!userData.editMode"
            (click)="closePopup()">
            Esci
        </button>
    </mat-dialog-actions>
</div>
