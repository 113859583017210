import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TokenManagerService} from './token-manager.service';
import {Group} from '../entity/group.component';
import {LoadingInfo} from './loading-info/loading-info';
import {Endpoints} from '../apps/utils/Endpoints';

@Injectable({
  providedIn: 'root'
})
export class GroupService extends LoadingInfo{

  constructor(private _httpClient: HttpClient, private _tokenManagerService: TokenManagerService) {
      super();
  }

    public async addNewGroup(group: Group) {

        this.whatIsLoading = "Inserimento nuovo gruppo";
        this.isLoadingSomething = true;
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken,
            name: group.name,
            companyId: group.companyId,
            responsibleEmail: group.userRef,
            type: group.type
        };

        await this._httpClient.post(
            Endpoints.ADD_NEW_GROUP, body, {
            headers: new HttpHeaders()
                .set("Content-Type", "application/json")
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async updateGroup(group: Group) {
        this.whatIsLoading = "Aggiornamento gruppo";
        this.isLoadingSomething = true;
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken,
            name: group.name,
            responsibleEmail: group.responsibleEmailAddress,
            groupId: group.id,
            type: group.type,
            companyId: group.companyId
        };

        await this._httpClient.post(
            Endpoints.EDIT_GROUP, body, {
            headers: new HttpHeaders()
                .set("Content-Type", "application/json")
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async deleteGroup(group: Group) {
        this.whatIsLoading = "Eliminazione gruppo";
        this.isLoadingSomething = true;
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken,
            groupId: group.id
        };

        await this._httpClient.post(
            Endpoints.DELETE_GROUP, body, {
            headers: new HttpHeaders()
                .set("Content-Type", "application/json")
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async getGruppiList(page: number, sortType: string, sortBy: string, pageSize: number, filter?: any) {
        this.whatIsLoading = "Caricamento gruppo";
        this.isLoadingSomething = true;
        let promise;
        let body;
        if(filter !== null && filter !== undefined) {
            body = {
                authToken: this._tokenManagerService.authToken,
                page: page,
                sortType: sortType,
                sortBy: sortBy,
                pageSize: pageSize,
                filterEnabled: true,
                groupNameFilter: filter.groupName,
                responsibleNameFilter: filter.responsibleName,
                companyIdFilter: filter.companyId
            };
        }else{
            body = {
                authToken: this._tokenManagerService.authToken,
                page: page,
                sortType: sortType,
                sortBy: sortBy,
                pageSize: pageSize,
                filterEnabled: false
            };
        }

        await this._httpClient.post(
            Endpoints.GET_GROUPS_LIST, body, {
            headers: new HttpHeaders()
                .set("Content-Type", "application/json")
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async getAllGruppi() {
        this.whatIsLoading = "Caricamento gruppi";
        this.isLoadingSomething = true;
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken
        };

        await this._httpClient.post(
            Endpoints.GET_ALL_GROUPS, body, {
            headers: new HttpHeaders()
                .set("Content-Type", "application/json")
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

    public async getAllGroupModerators(companyId?: number) {
        let promise;
        const body = {
            authToken: this._tokenManagerService.authToken
        };
        if (companyId !== null && companyId !== undefined) {
            body["companyId"] = companyId;
        }
        await this._httpClient.post(
            Endpoints.GET_GROUP_RESPONSIBLES, body, {
            headers: new HttpHeaders()
                .set("Content-Type", "application/json")
        }).toPromise().then(result => {
            this.isLoadingSomething = false;
            promise = result;
        }).catch(error => {
            this.isLoadingSomething = false;
            promise = error;
        });

        return promise;
    }

}
