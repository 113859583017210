import {DIPENDENTE, User} from './user.component';
import { Qualification } from "./qualification.component";
import { Group } from "./group.component";

export const DOCUMENT_TYPE = 4;

export const ALTRO = 0;
export const CDA_ASSEMBLEA = 1;
export const COMUNICAZIONI = 2;
export const CONTABILITA = 3;
export const PRIVACY = 4;
export const SICUREZZA = 5;

export interface Document {
    title?: string;
    isDeletable?: boolean;
    sharingDate?: string;
    description?: string;
    workerType?: number;
    associateOnly?: number;
    users?: number[];
    usersGroups?: number[];
    qualifications?: number[];
    companies?: number[];
    type?: number;
    filePath?: string;
    actions?: boolean;
}

export const EMPTY_DOCUMENT_PLACE_HOLDER: Document = {
    title: "Nessun documento presente"
};

export const EMPTY_LIST_DOCUMENT_PLACE_HOLDER: Document = {
    title: "Nessun documento trovato corrispondente ai criteri di ricerca inseriti"
};
