
import {QuestionReply} from './question-reply.component';
import {Qualification} from "./qualification.component";
import {Group} from './group.component';

interface resultArray {
    id: number;
    result: number;
    executionDate: number;
    executionDuration: number;
}

export interface FilledTest {
    title: string;
    type?: string;
    shareDate?: string;
    executionDate?: string;
    outcome?: string;
    targetQualification?: Qualification;
    targetUser?: {
        surname?: string;
        attempts?: number;
        bestResult?: resultArray;
        name?: string;
        companyName?: string;
        qualification?: string;
        fiscalCode?: string;
        group?: Group;
        executionDate?: string;
    };
    targetGroups?: Group;
    questionsReply?: QuestionReply[];
    score?: number;
    done?: boolean;
}

export const EMPTY_FILLED_TEST_PLACEHOLDER: FilledTest = {
    title:"Nessun test presente",
    targetUser: {
        surname: "Nessun test presente",
        name: undefined,
        companyName: undefined,
        qualification: undefined,
        fiscalCode: undefined,
        group: undefined,
        executionDate: undefined
    }
    };
