import {environment} from '../../../../environments/environment';

export class Endpoints {
    private static readonly V1 = '/api/v1';
    private static readonly BACKEND_BASE_URL = environment.BACKEND_URL + Endpoints.V1;

    public static readonly GET_AUTH_API = Endpoints.BACKEND_BASE_URL + '/auth/getAuth';
    public static readonly LOGIN = Endpoints.BACKEND_BASE_URL + '/auth/login';
    public static readonly SEND_RECOVERY_EMAIL = Endpoints.BACKEND_BASE_URL + '/auth/recover_password_request';
    public static readonly RECOVER_PASSWORD = Endpoints.BACKEND_BASE_URL + '/auth/recover_password';
    public static readonly GET_HOME_STATISTICS = Endpoints.BACKEND_BASE_URL + '/home/retrieveStatistics';

    public static readonly GET_USERS = Endpoints.BACKEND_BASE_URL + '/userManager/getAllUsers';
    public static readonly DELETE_USER = Endpoints.BACKEND_BASE_URL + '/userManager/deleteUser';
    public static readonly INSERT_NEW_USER = Endpoints.BACKEND_BASE_URL + '/userManager/insertNewUser';
    public static readonly GET_USER_INFO = Endpoints.BACKEND_BASE_URL + '/userManager/userInfo';
    public static readonly CHECK_USER_EXISTANCE = Endpoints.BACKEND_BASE_URL + '/userManager/userExists';
    public static readonly UPDATE_USER = Endpoints.BACKEND_BASE_URL + '/userManager/updateUser';
    public static readonly GET_USERS_BY_PAGE = Endpoints.BACKEND_BASE_URL + '/userManager/filterUsersByPage';
    public static readonly GET_COMPANY_MODERATORS = Endpoints.BACKEND_BASE_URL + '/userManager/getAllModerators';
    public static readonly GET_USERS_QUALIFICATIONS = Endpoints.BACKEND_BASE_URL + '/userManager/getAllQualifications';
    public static readonly GET_GROUP_RESPONSIBLES = Endpoints.BACKEND_BASE_URL + '/userManager/getAllResponsibles';
    public static readonly GET_TARGET_USERS = Endpoints.BACKEND_BASE_URL + '/userManager/getTargetUsers';

    public static readonly ADD_NEW_GROUP = Endpoints.BACKEND_BASE_URL + '/groupsManager/addNewGroup';
    public static readonly EDIT_GROUP = Endpoints.BACKEND_BASE_URL + '/groupsManager/editGroup';
    public static readonly DELETE_GROUP = Endpoints.BACKEND_BASE_URL + '/groupsManager/deleteGroup';
    public static readonly GET_ALL_GROUPS = Endpoints.BACKEND_BASE_URL + '/groupsManager/getAllGroup';
    public static readonly GET_GROUPS_LIST = Endpoints.BACKEND_BASE_URL + '/groupsManager/filterGroups';

    public static readonly UPLOAD_FILE = Endpoints.BACKEND_BASE_URL + '/fileManager/uploadFile';
    public static readonly OLD_DOWNLOAD_FILE = Endpoints.BACKEND_BASE_URL + '/fileManager/downloadFile';
    public static readonly DOWNLOAD_FILE = Endpoints.BACKEND_BASE_URL + '/fileManager/getFile';

    public static readonly GET_USER_DOSSIERS = Endpoints.BACKEND_BASE_URL + '/dossiersManager/getUserPrivateDocuments';
    public static readonly UPLOAD_DOSSIER = Endpoints.BACKEND_BASE_URL + '/dossiersManager/addPrivateDocument';
    public static readonly GET_DOSSIERS_TYPES = Endpoints.BACKEND_BASE_URL + '/dossiersManager/getDossiers';
    public static readonly DELETE_DOSSIER = Endpoints.BACKEND_BASE_URL + '/dossiersManager/deletePrivateDocument';
    public static readonly SUBMIT_PAYCHECKS = Endpoints.BACKEND_BASE_URL + '/dossiersManager/submitPaycheck';

    public static readonly SHARE_DOCUMENT = Endpoints.BACKEND_BASE_URL + '/documentsManager/shareDocument';
    public static readonly GET_AVAILABLE_TARGETS = Endpoints.BACKEND_BASE_URL + '/documentsManager/getAvailableTargets';
    public static readonly GET_ALL_DOCUMENTS = Endpoints.BACKEND_BASE_URL + '/documentsManager/getAllDocuments';
    public static readonly DELETE_DOCUMENT = Endpoints.BACKEND_BASE_URL + '/documentsManager/deleteDocument';
    public static readonly GET_DOCUMENT_DETAILS = Endpoints.BACKEND_BASE_URL + '/documentsManager/getSingleDocument';
    public static readonly GET_DOCUMENTS_BY_PAGE = Endpoints.BACKEND_BASE_URL + '/documentsManager/filterDocumentsByPage';

    public static readonly GET_ALL_REGIONS = Endpoints.BACKEND_BASE_URL + '/citiesManager/getDistricts';
    public static readonly GET_ALL_PROVINCES_BY_REGION = Endpoints.BACKEND_BASE_URL + '/citiesManager/getProvincesByDistrict';
    public static readonly GET_ALL_CITIES_BY_PROVINCE = Endpoints.BACKEND_BASE_URL + '/citiesManager/getCitiesByProvince';

    public static readonly SUBMIT_TEST = Endpoints.BACKEND_BASE_URL + '/testManager/shareTest';
    public static readonly GET_TESTS_BY_PAGE = Endpoints.BACKEND_BASE_URL + '/testManager/filterTestsByPage';
    public static readonly DELETE_TEST = Endpoints.BACKEND_BASE_URL + '/testManager/deleteTest';
    public static readonly DISABLE_AUTO_SHARE = Endpoints.BACKEND_BASE_URL + '/testManager/disableAutoShare';
    public static readonly GET_TEST_INFO = Endpoints.BACKEND_BASE_URL + '/testManager/getSingleTest';
    public static readonly GET_USER_REPLIES = Endpoints.BACKEND_BASE_URL + '/testManager/getUserReplies';
    public static readonly EXPORT_TEST = Endpoints.BACKEND_BASE_URL + '/testManager/exportTest';

    public static readonly CREATE_NEW_COMPANY = Endpoints.BACKEND_BASE_URL + '/companiesManager/addCompany';
    public static readonly GET_COMPANY_BY_ID = Endpoints.BACKEND_BASE_URL + '/companiesManager/getCompany';
    public static readonly GET_ALL_COMPANIES = Endpoints.BACKEND_BASE_URL + '/companiesManager/getCompanies';
    public static readonly DELETE_COMPANY = Endpoints.BACKEND_BASE_URL + '/companiesManager/deleteCompany';
    public static readonly MODIFY_COMPANY = Endpoints.BACKEND_BASE_URL + '/companiesManager/modifyCompany';
    public static readonly DELETE_SITE = Endpoints.BACKEND_BASE_URL + '/companiesManager/deleteSite';
    public static readonly EDIT_SITE = Endpoints.BACKEND_BASE_URL + '/companiesManager/modifySite';
    public static readonly ADD_BRANCH_SITE = Endpoints.BACKEND_BASE_URL + '/companiesManager/addSecondarySite';
    public static readonly GET_COMPANY_EMPLOYEES = Endpoints.BACKEND_BASE_URL + '/companiesManager/getCompanyEmployees';
    public static readonly GET_COMPANIES_NAMES = Endpoints.BACKEND_BASE_URL + '/companiesManager/getAvailableCompanies';
    public static readonly GET_COMPANY_GROUPS = Endpoints.BACKEND_BASE_URL + '/companiesManager/getCompanyGroups';

    public static readonly ADD_NEW_COMMUNICATION = Endpoints.BACKEND_BASE_URL + '/communicationsManager/shareCommunications';
    public static readonly GET_COMMUNICATIONS_BY_PAGE = Endpoints.BACKEND_BASE_URL + '/communicationsManager/filterCommunicationsByPage';
    public static readonly GET_COMMUNICATION_DETAILS = Endpoints.BACKEND_BASE_URL + '/communicationsManager/getSingleCommunication';
    public static readonly GET_COMMUNICATIONS_TYPES= Endpoints.BACKEND_BASE_URL + '/communicationsManager/getCommunicationTypes';
}

