
export class LoadingInfo {

    private _isLoadingSomething: boolean;
    private _whatIsLoading: string;

    get isLoadingSomething(): boolean {
        return this._isLoadingSomething;
    }

    set isLoadingSomething(value: boolean) {
        this._isLoadingSomething = value;
    }

    get whatIsLoading(): string {
        return this._whatIsLoading;
    }

    set whatIsLoading(value: string) {
        this._whatIsLoading = value;
    }
}
