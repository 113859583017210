import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import { fuseAnimations } from "@fuse/animations";

import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";


export const CONFIRM_OPTIONS = {
    RM_CONTACT: 0,
    RM_GROUP: 1,
    RM_USER: 2,
    RM_QUALIFICATION: 3,
    RM_DOCUMENT: 4,
    RM_TEST: 5,
    STOP_TEST: 6,
    RM_COMPANY: 7
}


@Component({
    selector: "new",
    templateUrl: "./confirm-dialog.component.html",
    styleUrls: ["./confirm-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})

export class ConfirmDialogModule implements OnInit {

    dialogTitle: string;
    dialogContent: string;
    operationType: number;

    /**
     * Constructor
     *
     */
    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogModule>,
        @Inject(MAT_DIALOG_DATA) public dialogData: any
    ) {
        this.dialogTitle = "Attenzione!"
        this.dialogContent = dialogData.content;
        this.operationType = dialogData.operationType;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }


}
