
export const QUALIFICATION_TYPE = 2;

export interface Qualification {
    id?: number;
    title: String;
    actions?: boolean;
}

export const EMPTY_QUALIFICATION_PLACE_HOLDER: Qualification = {
    id: null,
    title:"Nessuna qualifica presente",
    actions: false
};
