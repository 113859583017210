import { User } from "./user.component";

export const READING_CONFIRM_TYPE = 5;

export interface ReadingConfirm {
    user: User;
    read: boolean;
    readingDate?: string;
}

export const EMPTY_READ_CONFIRM_PLACEHOLDER: ReadingConfirm = {
    user: {
        qualification: {id: null, title: "Nessun utente presente", actions: false },
        accountMode: "",
        typeOfWorker: 0,
        associate: false,
        usersGroups: [],
        surname: "",
        name: "",
        fiscalCode: "",
        emailAddress: "",
        contacts: [],
        actions: false,
    },
    read: false,
};
