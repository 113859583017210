import {Component, OnInit, ViewEncapsulation, Inject} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {
    FilledTest,
    EMPTY_FILLED_TEST_PLACEHOLDER,
} from '../../../../entity/filled-test.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {TooltipPosition} from '@angular/material/tooltip';
import {TestService} from '../../../../services/test.service';
import {
    CONFLICT,
    METHOD_NOT_ALLOWED, MULTIPLE_CHOICES_QUESTION,
    STATUS_UNAUTHORIZED,
    TEST_TYPE_SURVEY,
    TEST_TYPE_VALUTATION_TEST
} from '../../../utils/commons.component';
import {ToastManager} from '../../../utils/toast-manager';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Test} from "../../../../entity/test.component";
import {DatePipe} from "@angular/common";
import {isIterable} from 'rxjs/internal-compatibility';
import {UserService} from '../../../../services/user.service';

@Component({
    selector: 'new',
    templateUrl: './show-test-dialog.component.html',
    styleUrls: ['./show-test-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ShowTestDialog extends ToastManager implements OnInit {
    toolTipPosition: TooltipPosition;
    title: string;
    type: string;
    testReplyColumnNames: string[];
    totalQuestionsCount: number;
    listLoadParameters: any;

    shareDatesCount: number;
    selectedShareDate: number;
    selectedShareDateLabel: string;

    filledTests: MatTableDataSource<FilledTest>;
    filledTestBaseData: FilledTest[];

    responseInfo: Test;

    constructor(
        private dialogRef: MatDialogRef<ShowTestDialog>,
        @Inject(MAT_DIALOG_DATA) public testData: any,
        private router: Router,
        public datePipe: DatePipe,
        public testService: TestService,
        private _dialog: MatDialog,
        private _sBar: MatSnackBar,
        public userService : UserService
    ) {
        super(_sBar);
        this.toolTipPosition = 'above';
        this.title = testData.title;
        this.type = testData.type;
        this.listLoadParameters = testData.listLoadParameters;
        this.shareDatesCount = 1;
        this.selectedShareDate = 0;
        this.selectedShareDateLabel = "";
        this.filledTestBaseData = [];
        this.filledTests = new MatTableDataSource<FilledTest>();
    }

    ngOnInit(): void {
        this.loadData(this.testData.testId, 0);
    }

    private updateTable(structure: FilledTest[]): void {
        this.filledTests.data = structure;
    }

    private checkEmptyTable(structure: FilledTest[]): void {
        if (structure.length <= 0) {
            structure.push(EMPTY_FILLED_TEST_PLACEHOLDER);
        } else if (structure[0].targetUser == null) {
            structure.splice(0, 1);
        }
        this.updateTable(structure);
    }

    public closeDialog(fromButton: boolean): void {
        this.dialogRef.close();
        if(fromButton) {
            window.location.hash = "/apps/tests-management/list"
        }
    }

    public showDetails(filledTest: FilledTest): void {
        this.router.navigate(['apps/tests-management/detail'], {state: {data: filledTest, totalQuestionsCount: this.totalQuestionsCount,
                testId: this.testData.testId, selectedShareData: this.selectedShareDateLabel, listLoadParameters: this.listLoadParameters}});
        this.closeDialog(false);
    }

    private loadData(testId: number, dateIndex: number) {
        this.testService.getTestInfo(testId, dateIndex).then(result => {
            let message: string = "";
            if (result['status'] === true) {
                this.responseInfo = result['test'];
                this.responseInfo.testTarget = result['testTarget'];
                let testSharingInfoMap = new Map(Object.entries(result['testSharingInfo']));
                let testSharingInfoMapSorted = new Map([...testSharingInfoMap].sort(
                    (a, b) => {
                        const firstItemSlice = a[0].split('/');
                        const secondItemSlice = b[0].split('/');
                        const first = new Date(parseInt(firstItemSlice[2]), parseInt(firstItemSlice[1]) - 1,
                            parseInt(firstItemSlice[0]));
                        const second = new Date(parseInt(secondItemSlice[2]), parseInt(secondItemSlice[1]) - 1,
                            parseInt(secondItemSlice[0]));
                        return first.getTime() < second.getTime() ? 1 : (first.getTime() > second.getTime() ? -1 : 0)
                    }
                ));
                let testSharingInfoJson = {};
                testSharingInfoMapSorted.forEach((value, key) => {
                    testSharingInfoJson[key] = value;
                });
                this.responseInfo.testSharingInfo = testSharingInfoJson;
                this.initInfos();
            } else if (result['status'] === METHOD_NOT_ALLOWED || result['status'] === STATUS_UNAUTHORIZED || result['status'] === CONFLICT) {
                message = result['error']['result'];
            } else {
                message = result['error']['result'];
            }
            this.showToast(message);
        });
    }

    private static getTypeString(testType: number) {
        switch (testType) {
            case TEST_TYPE_SURVEY:
                return "Sondaggio";
            case TEST_TYPE_VALUTATION_TEST:
                return "Test di valutazione";
            default:
                return "";
        }
    }

    switchDate(datePage: number) {
        this.selectedShareDate = datePage;
        this.loadData(this.testData.testId, this.selectedShareDate);
    }

    exportExcel() {
        this.testService.exportTest(this.testData.testId).then(result => {
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('display', 'none');
            let url = window.URL.createObjectURL(result);
            a.href = url;
            a.download = this.title + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);
        });
    }

    initInfos() {
        this.title = this.responseInfo.title;
        this.type = ShowTestDialog.getTypeString(this.responseInfo.testType);
        this.totalQuestionsCount = this.responseInfo.questions.filter((element) => {
            return element.type === MULTIPLE_CHOICES_QUESTION
        }).length;
        this.shareDatesCount = Object.keys(this.responseInfo.testSharingInfo).length
        this.selectedShareDateLabel = Object.keys(this.responseInfo.testSharingInfo)[this.selectedShareDate]
        let testToAdd: FilledTest;
        (this.responseInfo.testType === 1)?
            this.testReplyColumnNames = [
                'company',
                'group',
                'qualification',
                'surname',
                'name',
                'fiscalCode',
                'result',
                'date',
                'outcome'
            ] : this.testReplyColumnNames = [
            'company',
            'group',
            'qualification',
            'surname',
            'name',
            'fiscalCode',
            'date',
            'outcome'
        ];
        this.filledTestBaseData = [];
        let toSelect = (Object.keys(this.responseInfo.testSharingInfo)[this.selectedShareDate]);
        if (isIterable(this.responseInfo.testSharingInfo[toSelect])) {
            for(let element of this.responseInfo.testSharingInfo[toSelect]) {
                let bestResult = undefined;
                let outcome = undefined;
                if (element['results'].length > 0) {
                    bestResult = element['results'].reduce((prev, curr) => {
                        return prev.result > curr.result ? prev : curr;
                    })
                    outcome = bestResult?.passed ? "Superato" : "Non superato";
                }
                let userInfo = {
                    surname: element['user']['surname'],
                    name: element['user']['name'],
                    fiscalCode: element['user']['fiscalCode'],
                    companyNames: element['user']['companyNames'],
                    group: element['group'],
                    attempts: element['attempts'],
                    bestResult,
                    qualification: element['user']['qualification'],
                    executionDate: element['lastExecutionDate']
                }
                testToAdd = {
                    title: this.responseInfo.title,
                    type: ShowTestDialog.getTypeString(this.responseInfo.testType),
                    targetUser: userInfo,
                    score: element['result'],
                    outcome,
                    done: element['done'],
                    shareDate: this.testData.shareDate
                }
                this.filledTestBaseData.push(testToAdd);
            }
        }
        this.checkEmptyTable(this.filledTestBaseData);
    }

    public ellipsify(str: string) {
        if (str && str.length > 40) {
            return (str.substring(0, 40) + "...");
        }
        else {
            return str;
        }
    }
}
